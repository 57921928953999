import { useState, useEffect } from "react";
import { useAppContext } from "../app/context/AppContext";

interface Referral {
  id: string;
  user: string;
  heartbeat: string;
  status: "Success" | "Pending";
  date: string;
}

interface ReferralStats {
  totalReferralPoints: number;
  successfulReferralsAmount: number;
  pendingReferralsAmount: number;
}

interface UseReferralsReturn {
  referrals: Referral[];
  stats: ReferralStats;
  loading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export const useReferrals = (): UseReferralsReturn => {
  const { accessToken } = useAppContext();
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [stats, setStats] = useState<ReferralStats>({
    totalReferralPoints: 0,
    successfulReferralsAmount: 0,
    pendingReferralsAmount: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchReferrals = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/referrals`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch referrals");
      }

      const data = await response.json();
      console.log(data);
      const transformedReferrals = data.referrals
        .map((ref: any) => ({
          id: ref.id,
          user: ref.inviteeEmail || "Anonymous",
          heartbeat: ref.inviteeHeartbeats.toString(),
          status: ref.status as "Success" | "Pending",
          date: new Date(ref.invitedAt).toLocaleDateString(
            navigator.language || "en-US",
            {
              month: "short",
              day: "numeric",
              year: "numeric",
            }
          ),
        }))
        .sort(
          (a: any, b: any) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        );

      setReferrals(transformedReferrals);
      setStats({
        totalReferralPoints: data.totalReferralPoints,
        successfulReferralsAmount: data.successfulReferralsAmount,
        pendingReferralsAmount: data.pendingReferralsAmount,
      });
    } catch (err) {
      setError(err instanceof Error ? err : new Error("An error occurred"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  return { referrals, stats, loading, error, refetch: fetchReferrals };
};
