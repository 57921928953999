import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAppContext } from "../app/context/AppContext";

interface CampaignRequirements {
  min_referrals?: number;
  min_heartbeat?: number;
  min_points?: number;
  [key: string]: any; // Allow for any additional requirements
}

export interface Campaign {
  id: string;
  type: string;
  tier_id: number;
  title: string;
  subtitle: string;
  points_required: number;
  points_reward: number;
  requirements: CampaignRequirements;
  active: boolean;
  created_at: string;
  updated_at: string;
  status: "claimable" | "claimed" | "locked";
}

export const useCampaigns = (type: "referral" | "heartbeat") => {
  const { accessToken, user, refreshUserData } = useAppContext();
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const [isClaimingReward, setIsClaimingReward] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const currentClaimRequest = useRef<{ [key: string]: boolean }>({});

  const fetchCampaigns = async () => {
    try {
      console.log(type);
      const response = await api.get(`/campaigns/${type}/status`);
      setCampaigns(response.data);
    } catch (err) {
      setError("Failed to fetch campaigns");
      console.error("Error fetching campaigns:", err);
    } finally {
      setLoading(false);
    }
  };

  const claimReward = async (campaignId: string) => {
    if (currentClaimRequest.current[campaignId]) {
      return false;
    }

    if (isClaimingReward) {
      return false;
    }

    try {
      currentClaimRequest.current[campaignId] = true;
      setIsClaimingReward(true);

      await api.post(`/campaigns/${campaignId}/claim`);
      await fetchCampaigns();
      await refreshUserData();

      return true;
    } catch (err) {
      console.error("Error claiming reward:", err);
      return false;
    } finally {
      setIsClaimingReward(false);
      currentClaimRequest.current[campaignId] = false;
    }
  };

  useEffect(() => {
    fetchCampaigns();
    refreshUserData();
  }, [accessToken]);

  return {
    campaigns,
    loading,
    error,
    claimReward,
    refreshCampaigns: fetchCampaigns,
  };
};
