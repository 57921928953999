import AppWrapper from "app/wrapper";
import { AppContextProvider } from "app/context/AppContext";
import isMobile from "ismobilejs";
import MobileDisclaimer from "features/maintainance/mobileDisclaimer";
function App() {
  if (isMobile(window.navigator).any) {
    return <MobileDisclaimer />;
  }
  return (
    <AppContextProvider>
      <AppWrapper />
    </AppContextProvider>
  );
}

export default App;
