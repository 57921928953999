import ReactDOM from "react-dom/client";
import "./app/style/index.css";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import LoadingSpinner from "./components/common/LoadingSpinner";
import App from "./app/App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Suspense fallback={<LoadingSpinner />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
);
