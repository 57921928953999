import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartOptions,
} from "chart.js";

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

interface ComingSoonWidgetProps {
  className?: string;
  title: string;
}

const ComingSoonWidget: React.FC<ComingSoonWidgetProps> = ({
  className,
  title,
}) => {
  // Chart data and options
  const chartData = {
    labels: Array.from({ length: 24 }, (_, i) => `${i}`),
    datasets: [
      {
        label: "Primary Metric",
        data: Array.from({ length: 24 }, () => Math.random() * 100),
        borderColor: "rgba(99, 179, 237, 0.8)",
        backgroundColor: "rgba(99, 179, 237, 0.2)",
        tension: 0.4,
        pointRadius: 2,
        pointBackgroundColor: "rgba(99, 179, 237, 1)",
        borderWidth: 3,
        fill: true,
      },
      {
        label: "Secondary Metric",
        data: Array.from({ length: 24 }, () => Math.random() * 80 + 20),
        borderColor: "rgba(236, 72, 153, 0.8)",
        backgroundColor: "rgba(236, 72, 153, 0.1)",
        tension: 0.4,
        pointRadius: 2,
        pointBackgroundColor: "rgba(236, 72, 153, 1)",
        borderWidth: 3,
        fill: true,
      },
    ],
  };

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "rgb(156 163 175)",
          padding: 20,
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: true,
        text: "Coming Soon Statistics",
        color: "rgb(156 163 175)",
        padding: 20,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Time (hours)",
          color: "rgb(156 163 175)",
          padding: 10,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "rgb(156 163 175)",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Value",
          color: "rgb(156 163 175)",
          padding: 10,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "rgb(156 163 175)",
        },
      },
    },
    animation: {
      duration: 2000,
    },
  };

  return (
    <div className={className}>
      <div className="relative   flex flex-col items-center justify-center h-full  bg-background-secondary-light dark:bg-background-secondary-dark p-5 overflow-hidden">
        <div className="absolute inset-0 filter blur-[10px]  opacity-80 p-5">
          <Line data={chartData} options={chartOptions} />
        </div>

        <h3 className="relative mt-3 text-xl opacity-55 font-normal text-primary dark:text-white z-10">
          {title}
        </h3>
      </div>
    </div>
  );
};

export default ComingSoonWidget;
