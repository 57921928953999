interface ProgressBarProps {
  progress: number;
  className?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  className = "",
}) => {
  return (
    <div className={`relative ${className}`}>
      <div className="h-4 bg-status-locked-light dark:bg-status-locked-dark w-full -translate-y-1/2">
        <div
          className="h-full bg-status-completed-light dark:bg-status-completed-dark rounded-full transition-all duration-500"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
