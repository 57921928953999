import { useEffect, useState } from "react";
import ComingSoonWidget from "./ComingSoonWidget";

export default function NodeUptime({ pointsToady }: { pointsToady: number }) {
  const [hb, setHb] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  function getHb(points: number) {
    const hbPerPoint = 25;
    return Math.floor(points / hbPerPoint);
  }

  function calculateHours(hb: number) {
    return Math.floor(hb / 4);
  }

  function calculateMinutes(hb: number) {
    return (hb % 4) * 15;
  }

  useEffect(() => {
    setHb(getHb(pointsToady));
    setHours(calculateHours(getHb(pointsToady)));
    setMinutes(calculateMinutes(getHb(pointsToady)));
  }, [pointsToady]);
  return (
    <div className="flex gap-5 flex-col">
      <div className="bg-background-secondary-light dark:bg-background-secondary-dark flex flex-col gap-4 p-8">
        <h2 className="text-gray-600 dark:text-gray-400 text-sm">
          Node uptime
        </h2>
        <div className="flex justify-between">
          <div className="flex flex-col gap-0.5">
            <h2 className="text-2xl dark:text-white">
              {hb}
              <span className="ml-0.5 text-base text-gray-500">/96</span>
            </h2>
            <h2 className="text-gray-500 text-sm dark:text-gray-400 ">
              Heartbeats (HB) Today
            </h2>
          </div>
          <div className="flex gap-2 items-center">
            <div className="flex flex-col items-center gap-1">
              <div className="h-10 w-10 grid place-content-center bg-gray-200">
                <h2>{hours}</h2>
              </div>
              <h2 className="text-gray-600 text-xs dark:text-gray-300">
                hours
              </h2>
            </div>
            <h1 className="mb-5 dark:text-white">:</h1>
            <div className="flex flex-col items-center gap-1">
              <div className="h-10 w-10 grid place-content-center bg-gray-200">
                <h2>{minutes}</h2>
              </div>
              <h2 className="text-gray-600 text-xs dark:text-gray-300">
                minutes
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* <ComingSoonWidget title="Coming soon" /> */}
    </div>
  );
}
