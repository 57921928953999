import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../components/icons";

interface ReferralData {
  id: string;
  user: string;
  status: "Success" | "Pending" | "Claimable";
  date: string;
  heartbeat: string | number;
}

interface TableWidgetProps {
  data: ReferralData[];
  className?: string;
  viewMoreLink?: string;
  limit: number;
  onClaimReferral?: (referralId: string) => Promise<void>;
}

const TableWidget: React.FC<TableWidgetProps> = ({
  data,
  className = "",
  viewMoreLink = "",
  limit = 10,
  onClaimReferral,
}) => {
  return (
    <div className={`${className} p-6`}>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-gray-600 dark:text-gray-400 text-sm">
          Referral Status
        </h3>
        {viewMoreLink && (
          <button className="text-button-green-light hover:text-button-green-dark dark:text-button-green-dark text-sm flex items-center gap-1">
            <Link to={viewMoreLink} className="flex items-center gap-1">
              View more
              <Icon name="arrow" size={16} />
            </Link>
          </button>
        )}
      </div>

      <div className="overflow-x-auto">
        {/* Header Row */}
        <div className="grid pl-4 grid-cols-5 text-left text-base bg-background-tertiary-light dark:bg-background-tertiary-dark text-gray-500 dark:text-gray-400 mb-4 h-14">
          <div className="text-text-primary-light dark:text-text-primary-dark flex items-center">
            User
          </div>
          <div className="text-text-primary-light dark:text-text-primary-dark flex items-center">
            Heartbeat
          </div>
          <div className="text-text-primary-light dark:text-text-primary-dark flex items-center">
            Status
          </div>
          <div className="text-text-primary-light dark:text-text-primary-dark flex items-center">
            Date
          </div>
          <div className="text-text-primary-light dark:text-text-primary-dark flex items-center">
            Action
          </div>
        </div>

        {/* Data Rows */}
        <div className="divide-y divide-background-divide-light dark:divide-background-divide-dark">
          {data.slice(0, limit).map((row, index) => (
            <div key={index} className="pl-4 grid grid-cols-5 text-sm py-3">
              <div className="text-gray-900 dark:text-gray-200 text-ellipsis ">
                {row.user}
              </div>

              <div className="text-gray-900 dark:text-gray-200">
                <div className="flex items-center gap-2">
                  <Icon name="heart-beat" size={20} />
                  <span>{row.heartbeat} HB</span>
                </div>
              </div>

              <div>
                <span
                  className={`px-2 py-1 text-xs w-18 inline-block text-center ${
                    row.status === "Success"
                      ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                      : row.status === "Claimable"
                      ? "bg-background-primary-light text-gray-800 dark:bg-background-primary-dark dark:text-gray-200"
                      : "bg-background-tertiary-light text-gray-800 dark:bg-gray-700 dark:text-gray-200"
                  }`}
                >
                  {row.status}
                </span>
              </div>

              <div className="text-gray-900 dark:text-gray-200">{row.date}</div>
              {row.status === "Claimable" && (
                <div>
                  <button 
                    onClick={() => onClaimReferral?.(row.id)} 
                    className="bg-black text-white rounded-full px-4 text-xs py-1 max-w-18"
                  >
                    claim
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableWidget;
