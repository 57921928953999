import React from "react";
import { useTheme } from "app/context/ThemeContext";
import { Icon } from "components/icons/Icon";
import HeaderWallet from "./HeaderWallet";
import { useAppContext } from "app/context/AppContext";
import { formatNumber } from "utils/helper";
import ScrambleIn from "utils/text-animation";

const Header: React.FC<{ title: string }> = ({ title }) => {
  const { userStats } = useAppContext();
  const { darkMode, toggleDarkMode } = useTheme();
  return (
    <header className="flex-shrink-0  bg-background-primary-light dark:bg-background-primary-dark border-b border-gray-200 dark:border-gray-700">
      <div className="flex justify-between items-center px-6 py-4">
        <h1 className="text-xl font-medium text-gray-900 dark:text-white">
          <ScrambleIn
            characters={title}
            className="text-xl font-medium text-gray-900 dark:text-white"
            key={title}
            text={title}
            scrambleSpeed={20}
            scrambledLetterCount={5}
            autoStart={true}
          />
        </h1>
        <div className="flex items-center space-x-4">
          <HeaderWallet />
          <div className="relative items-center flex gap-4">
            {userStats && (
              <div className="flex   items-center gap-1">
                <Icon
                  name="star"
                  className="text-gray-700 dark:text-gray-300"
                  size={16}
                />
                <h2 className="text-lg  font-medium text-gray-900 dark:text-white">
                  {formatNumber(userStats.points_total)}
                </h2>
                <h1 className="text-[10px] font-medium mt-1 text-gray-900 dark:text-white">
                  PTS
                </h1>
              </div>
            )}
            <button
              onClick={toggleDarkMode}
              className="relative w-[52px] h-[24px] rounded-full bg-white border border-gray-200 transition-colors duration-200 ease-in-out focus:outline-none "
            >
              <div
                className={`absolute top-[1px] w-[20px] h-[20px] rounded-full bg-black dark:bg-black transform transition-transform duration-200 ease-in-out ${
                  darkMode ? "translate-x-[27px]" : "translate-x-[4px]"
                }`}
              />
              <div className="absolute inset-0 flex items-center justify-between px-[6px]">
                <Icon
                  name="sun"
                  size={14}
                  className={`transform translate-x-[1px] ${
                    darkMode ? "text-gray-400" : "text-white"
                  }`}
                />
                <Icon
                  name="moon"
                  size={14}
                  className={`transform translate-x-[1px] ${
                    darkMode ? "text-gray-400" : "text-gray-400"
                  }`}
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
