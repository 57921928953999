import React, { useState } from "react";
import { Icon } from "components/icons/Icon";
import { useAppContext } from "app/context/AppContext";

const HeaderWallet: React.FC = () => {
  const { user } = useAppContext();
  const [copied, setCopied] = useState(false);

  const copyAddress = async () => {
    if (user?.wallet) {
      await navigator.clipboard.writeText(user.wallet);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <div className="flex items-center">
      {user?.wallet && (
        <div className="flex items-center gap-1">
          <span className="text-sm text-text-secondary-light dark:text-text-secondary-dark">
            Wallet
          </span>
          <div className="flex items-center">
            <div className="px-3 py-1.5 border border-gray-200 dark:border-gray-700 text-sm bg-background-primary-light dark:bg-background-primary-dark rounded-l-lg text-text-primary-light dark:text-text-primary-dark">
              {user.wallet.slice(0, 6)}...{user.wallet.slice(-4)}
            </div>
            <button
              onClick={copyAddress}
              className="px-3 py-1.5 border border-gray-200 dark:border-gray-700 text-sm bg-background-secondary-light dark:bg-background-secondary-dark rounded-r-lg border-l-0 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors flex items-center"
            >
              <Icon
                name={copied ? "check" : "copy"}
                className={`w-5 h-5 ${
                  copied
                    ? "text-green-500"
                    : "text-text-secondary-light dark:text-text-secondary-dark"
                }`}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderWallet;
