import { useAppContext } from "app/context/AppContext";
import { Icon } from "components/icons/Icon";
import { useState } from "react";

const ReferralTicket = () => {
  const [copied, setCopied] = useState(false);
  const { user } = useAppContext();

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(user.personal_code);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative bg-ticket-blue text-white p-4 w-full transition-all duration-300">
      <div className="absolute -left-1 top-1/2 w-3 h-6 bg-sidebar-light dark:bg-sidebar-dark rounded-r-full transform -translate-y-1/2" />
      <div className="absolute -right-1 top-1/2 w-3 h-6 bg-sidebar-light dark:bg-sidebar-dark rounded-l-full transform -translate-y-1/2" />

      <div className="flex justify-center mb-1">
        <Icon name="rocket" className="h-4 w-4" />
      </div>

      <h3 className="text-base font-medium text-center mb-2">Refer a Friend</h3>

      <p className="text-xs text-center text-white/80 mb-4">
        Copy code to invite friends
      </p>

      <button
        onClick={handleCopy}
        className="w-full text-xs flex items-center justify-center gap-2 bg-ticket-dark hover:bg-black/80 text-white py-2 px-4 transition-colors duration-200"
      >
        <span>{copied ? "Copied!" : "Copy Code"}</span>
        <Icon name={copied ? "check" : "copy"} className="h-4 w-4 text-white" />
      </button>
    </div>
  );
};

export default ReferralTicket;
