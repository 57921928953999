import React, { useState } from "react";
import classNames from "classnames";
import { Icon } from "../../../../../components/icons/Icon";
import { Task } from "../../../../../types/task";
import { formatNumber } from "utils/helper";

interface BonusRewardsProps {
  className?: string;
  title?: string;
  tasks: Task[];
  onTaskAction?: (taskId: string, actionType?: "claim") => void;
  onAgreeChange?: (agreed: boolean) => void;
  agreed?: boolean;
}

const BonusRewards: React.FC<BonusRewardsProps> = ({
  className,
  title = "Additional Verification",
  tasks,
  onTaskAction,
  onAgreeChange,
  agreed = false,
}) => {
  const [clickedTasks, setClickedTasks] = useState<string[]>([]);

  // Helper function to check if mandatory tasks are completed
  const areMandatoryTasksCompleted = () => {
    return tasks
      .filter(task => task.mandatory)
      .every(task => task.status === "completed");
  };

  return (
    <div
      className={classNames(
        "flex flex-col items-start p-6 gap-6 w-full",
        "bg-[#F0F4F5] dark:bg-[#25272B]",
        className
      )}
    >
      <h3 className="text-xl font-medium mb-6 text-text-primary-light dark:text-text-primary-dark">
        {title}
      </h3>

      <div className="flex flex-row  gap-6 w-full justify-around max-w-[1200px]">
        {tasks.map((task, index) => (
          <div
            key={task.id}
            className="w-[251px] flex flex-col items-start gap-4"
          >
            {/* Head Section */}
            <div className="flex flex-row items-center w-full gap-2">
              {/* Number Circle */}
              <div
                className={classNames(
                  "w-6 h-6 rounded-full flex items-center justify-center text-xs",
                  task.status === "completed"
                    ? "bg-[#D8E3E6] dark:bg-[#2C2E33] text-[#3C3F40] dark:text-[#D8E3E6]"
                    : index === tasks.findIndex((t) => t.status === "pending")
                    ? "bg-[#0044FF] text-white"
                    : "bg-[#D8E3E6] dark:bg-[#2C2E33] text-[#797E80]"
                )}
              >
                {task.taskNumber}
              </div>

              {/* Connecting Line */}
              <div className="flex-grow h-[1px] bg-[#D8E3E6] dark:bg-[#2C2E33]" />

              {/* Points Badge */}
              <div
                className={classNames(
                  "flex items-center px-3 py-0.5 gap-1 rounded-full",
                  index === tasks.findIndex((t) => t.status === "pending")
                    ? "bg-[#0044FF] text-[#FAFCFC]"
                    : task.status === "completed"
                    ? "bg-[#BCE6E3] dark:bg-[#325359] text-[#1D1F23] dark:text-[#FAFCFC]"
                    : "border border-[#D8E3E6] dark:border-[#2C2E33] text-[#797E80]"
                )}
              >
                <Icon name="star" size={16} className="text-current" />
                <span className="font-sans text-base">
                  {formatNumber(task.points)}
                  <span className="text-[10px] ml-0.5">PTS</span>
                </span>
              </div>
            </div>

            {/* Card */}
            <div
              className={classNames(
                "w-full p-6 flex flex-col justify-between gap-4 rounded-lg h-[144px]",
                index === tasks.findIndex((t) => t.status === "pending")
                  ? "bg-white dark:bg-[#1D1F23]  border border-[#0044FF]"
                  : task.status === "completed"
                  ? "bg-white  dark:bg-[#1D1F23]"
                  : "bg-[#D8E3E6] dark:bg-[#2C2E33]"
              )}
            >
              <div className="flex flex-col gap-2 ">
                <div className="text-xl  text-[#797E80]">
                  {task.type === "wallet" ? (
                    <Icon name="wallet" size={20} color="#797E80" />
                  ) : (
                    (task.type === "x" ||
                      task.title === "Follow X Account" ||
                      task.title === "follow us on X") && (
                      <Icon name="x-logo" size={20} color="#797E80" />
                    )
                  )}
                  {task.title === "Engage with post" && (
                    <Icon name="user-plus" size={20} color="#797E80" />
                  )}
                  {(task.type === "discord" ||
                    task.title === "Join Discord" ||
                    task.title === "join our discord") && (
                    <Icon name="discord" size={20} color="#797E80" />
                  )}
                </div>
                <h4
                  className={classNames(
                    "font-sans  text-lg",
                    task.status === "pending" &&
                      index !== tasks.findIndex((t) => t.status === "pending")
                      ? "text-[#797E80]"
                      : "text-[#1D1F23] dark:text-[#FAFCFC]"
                  )}
                >
                  {task.title}
                </h4>
              </div>

              {task.status === "completed" ? (
                <div className="flex items-center gap-2 px-2 py-1 bg-[#F0F4F5] dark:bg-[#25272B] w-fit rounded">
                  <Icon name="check-circle" size={16} className="text-[#BCE6E3]" />
                  <span className="text-[14px] text-[#BCE6E3]">Completed</span>
                </div>
              ) : task.status === "pending" && clickedTasks.includes(task.id) ? (
                <div className="flex items-center gap-2 px-4 py-2 bg-[#F0F4F5] dark:bg-[#25272B] w-fit rounded">
                  <Icon
                    name="clock-countdown"
                    size={16}
                    className="text-[#797E80]"
                  />
                  <span className="text-[14px] text-[#797E80]">Pending</span>
                </div>
              ) : !task.mandatory && !areMandatoryTasksCompleted() ? (
                <div className="flex justify-start w-full">
                  <div className="flex flex-row justify-center items-center px-4 py-2 gap-2 w-[111px] h-[40px] bg-[#F0F4F5] dark:bg-[#25272B] rounded">
                    <Icon name="lock" size={16} className="text-[#797E80]" />
                    <span className="text-[14px] text-[#797E80]">Locked</span>
                  </div>
                </div>
              ) : task.action ? (
                <div className="flex justify-start w-full">
                  {!clickedTasks.includes(task.id) ? (
                    <button
                      onClick={() => {
                        onTaskAction?.(task.id);
                        setClickedTasks((prev) => [...prev, task.id]);
                      }}
                      disabled={task.type !== "wallet" && !agreed}
                      className={classNames(
                        "flex flex-row justify-center items-center px-4 py-2 gap-2",
                        "min-w-[149px] h-[40px]",
                        "rounded",
                        task.type === "wallet" || agreed
                          ? "bg-[#0044FF] text-[#FAFCFC] cursor-pointer"
                          : "bg-gray-400 text-gray-200 cursor-not-allowed",
                        "font-sans text-base"
                      )}
                    >
                      {task.action.label}
                      {task.action?.label === "Link Wallet" ? (
                        <Icon name="wallet" size={20} className="text-[#FAFCFC]" />
                      ) : (
                        <Icon name="arrow" size={20} className="text-[#FAFCFC]" />
                      )}
                    </button>
                  ) : task.action.secondaryAction && (
                    <button
                      onClick={() => onTaskAction?.(task.id, "claim")}
                      disabled={!agreed}
                      className={classNames(
                        "flex flex-row justify-center items-center px-4 py-2 gap-2",
                        "min-w-[143px] h-[40px]",
                        "rounded",
                        agreed
                          ? "bg-[#1D1F23] text-[#FAFCFC] cursor-pointer"
                          : "bg-gray-400 text-gray-200 cursor-not-allowed",
                        "font-sans text-base"
                      )}
                    >
                      <Icon name="hand-grabbling" size={20} className="text-[#FAFCFC]" />
                      {task.action.secondaryAction.label}
                    </button>
                  )}
                </div>
              ) : (
                <div className="flex  items-center gap-2 px-4 py-2  bg-[#F0F4F5] dark:bg-[#25272B] w-fit rounded">
                  <Icon
                    name="clock-countdown"
                    size={16}
                    className="text-[#797E80]"
                  />
                  <span className="text-[14px] text-[#797E80]">Pending</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Agreement Checkbox */}
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          checked={agreed}
          onChange={(e) => onAgreeChange?.(e.target.checked)}
          className="w-5 h-5 rounded bg-[#1D1F23] dark:bg-[#FAFCFC] border-none focus:ring-0"
        />
        <span className="font-sans text-base text-[#1D1F23] dark:text-[#FAFCFC]">
          I agree to let Deform access to my information via this verification
          process.
        </span>
      </div>
    </div>
  );
};

export default BonusRewards;
