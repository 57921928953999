import { useState } from "react";
import { showToast } from "../../../../../components/common/Toast";
import { changePassword } from "../../../../../app/data/api";
import { useAppContext } from "../../../../../app/context/AppContext";
import { useNavigate } from "react-router-dom";

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
  const { accessToken } = useAppContext();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const navigator = useNavigate();
  const { setUser, setAccessToken } = useAppContext();

  const [redirecting, setRedirecting] = useState(false);
  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError("");

    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await changePassword(accessToken, newPassword);
      if (response?.data?.message === "Password updated successfully") {
        showToast({
          message: "Password changed successfully",
          type: "success",
        });
        onClose();
      }
    } catch (error) {
      const errorMessage = "An error occurred while changing the password";
      setPasswordError(errorMessage);
      showToast({ message: errorMessage, type: "error" });
      console.error("Password change error:", error);
    }
  };

  const version = 2; // 1 is change internally here. 2 is logout and forward them to reset-password page

  async function handleRedirect() {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    setUser(null);
    setAccessToken(null);
    setRedirecting(true);
    setTimeout(() => {
      navigator("/auth/forgot-password");
      setRedirecting(false);
    }, 300);
  }

  if (version === 2) {
    //  exact popup but with message : You will be logged out an be redirect to reset password page. buttons: cancel and continue
    return (
      <div className="fixed inset-0 text-sm min-h-screen top-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
        <div className="bg-background-primary-light dark:bg-background-primary-dark w-full max-w-md p-8">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-lg text-text-primary-light dark:text-text-primary-dark font-light">
              Change password
            </h2>
            <button
              onClick={onClose}
              className="text-text-primary-light dark:text-text-primary-dark opacity-50 hover:opacity-100"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h2 className="text-base text-text-primary-light dark:text-text-primary-dark font-light">
            You will be logged out and redirected to reset password page where
            you can do this operation.
          </h2>

          <div className="flex justify-end space-x-4 mt-8">
            <button
              type="button"
              onClick={onClose}
              className="px-8 py-3  w-1/2 border border-background-tertiary-light dark:border-background-tertiary-dark text-text-primary-light dark:text-text-primary-dark hover:bg-background-tertiary-light dark:hover:bg-background-tertiary-dark "
            >
              Cancel
            </button>
            <button
              onClick={handleRedirect}
              className="px-8 py-3 w-1/2 bg-secondary text-white hover:bg-opacity-90 "
            >
              {redirecting ? "Redirecting..." : "Continue"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 text-sm min-h-screen top-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-background-primary-light dark:bg-background-primary-dark w-full max-w-md p-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-lg text-text-primary-light dark:text-text-primary-dark font-light">
            Change password
          </h2>
          <button
            onClick={onClose}
            className="text-text-primary-light dark:text-text-primary-dark opacity-50 hover:opacity-100"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={() => handleSubmit} className="space-y-6">
          <div>
            <label className="block  text-text-primary-light dark:text-text-primary-dark mb-2">
              New Password
            </label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setPasswordError("");
                }}
                className="w-full p-4 border border-background-tertiary-light dark:border-background-tertiary-dark bg-background-primary-light dark:bg-background-primary-dark text-text-primary-light dark:text-text-primary-dark placeholder-text-secondary-light dark:placeholder-text-secondary-dark"
                placeholder="Enter new password"
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-text-secondary-light dark:text-text-secondary-dark hover:text-secondary"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d={
                      showNewPassword
                        ? "M15 12a3 3 0 11-6 0 3 3 0 016 0z M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        : "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>

          <div>
            <label className="block  text-text-primary-light dark:text-text-primary-dark mb-2">
              Confirm Password
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordError("");
                }}
                className="w-full p-4 border border-background-tertiary-light dark:border-background-tertiary-dark bg-background-primary-light dark:bg-background-primary-dark text-text-primary-light dark:text-text-primary-dark placeholder-text-secondary-light dark:placeholder-text-secondary-dark"
                placeholder="Confirm new password"
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-text-secondary-light dark:text-text-secondary-dark hover:text-secondary"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d={
                      showConfirmPassword
                        ? "M15 12a3 3 0 11-6 0 3 3 0 016 0z M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        : "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>

          {passwordError && (
            <div className="text-red-500 ">{passwordError}</div>
          )}

          <div className="flex justify-end space-x-4 mt-8">
            <button
              type="button"
              onClick={onClose}
              className="px-8 py-3  w-1/2 border border-background-tertiary-light dark:border-background-tertiary-dark text-text-primary-light dark:text-text-primary-dark hover:bg-background-tertiary-light dark:hover:bg-background-tertiary-dark "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-8 py-3 w-1/2 bg-secondary text-white hover:bg-opacity-90 "
            >
              Change password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
