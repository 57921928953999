import React from "react";
import { Icon } from "../../../../../components/icons/Icon";
import { formatNumber } from "utils/helper";

interface TaskCardProps {
  title: string;
  subtitle: string;
  points: number;
  isActive?: boolean;
  isCompleted?: boolean;
  isLocked?: boolean;
  showStatusIcon?: boolean;
  iconType?: "default" | "connectivity-streak";
  onClick?: () => void;
}

const TaskCard: React.FC<TaskCardProps> = ({
  title,
  subtitle,
  points,
  isActive,
  isCompleted,
  isLocked,
  showStatusIcon = false,
  iconType = "default",
  onClick,
}) => {
  return (
    <div className="relative">
      <div
        onClick={onClick}
        className={`
          w-44 py-6 rounded-lg flex flex-col items-center justify-between h-48
          ${isActive ? "border border-brand-blue-1 cursor-pointer" : ""}
          ${
            isLocked
              ? "bg-status-locked-light dark:bg-status-locked-dark"
              : "bg-background-primary-light dark:bg-background-primary-dark"
          }
        `}
      >
        {/* Icon Container */}
        <div className="bg-icon-background-light dark:bg-icon-background-dark p-1.5 rounded">
          <Icon
            name={iconType === "connectivity-streak" ? "flame" : "rocket"}
            className={`w-5 h-5 ${
              isLocked
                ? "text-icon-secondary-light dark:text-icon-secondary-dark"
                : "text-icon-primary-light dark:text-icon-primary-dark"
            }`}
          />
        </div>

        {/* Title and Subtitle */}
        <div className="text-center">
          <h3
            className={`text-2xl mb-1 ${
              isLocked
                ? "text-icon-secondary-light dark:text-icon-secondary-dark"
                : "text-icon-primary-light dark:text-icon-primary-dark"
            }`}
          >
            {title}
          </h3>
          <p
            className={`text-xs ${
              isLocked
                ? "text-icon-secondary-light dark:text-icon-secondary-dark"
                : "text-icon-primary-light dark:text-icon-primary-dark"
            }`}
          >
            {subtitle}
          </p>
        </div>

        {/* Points Badge */}
        <div
          onClick={onClick}
          className={`
           flex items-end px-3 py-0.5 gap-1 rounded-full
            ${
              isActive
                ? "bg-[#0044FF] text-[#FAFCFC] cursor-pointer"
                : isCompleted
                ? "bg-[#BCE6E3] border-[#BCE6E3] border dark:bg-[#325359] dark:border-[#325359] text-[#1D1F23] dark:text-[#FAFCFC]"
                : "border border-[#b3b3b3] dark:border-[#585859] text-[#797E80]"
            }
          `}
        >
          <Icon
            name="star"
            className={`w-4 h-4 ${
              isActive || isCompleted
                ? "text-icon-primary-dark"
                : "text-icon-secondary-light self-center dark:text-icon-secondary-dark"
            }`}
          />
          <span
            className={`text-base ${
              isActive || isCompleted
                ? "text-icon-primary-dark"
                : "text-icon-secondary-light dark:text-icon-secondary-dark"
            }`}
          >
            {formatNumber(points)}
          </span>
          <span
            className={`text-[10px] mb-0.5 ${
              isActive || isCompleted
                ? "text-icon-primary-dark"
                : "text-icon-secondary-light dark:text-icon-secondary-dark"
            }`}
          >
            PTS
          </span>
        </div>
        {isActive ? (
          <div className="mt-1 bg-black rounded-full px-4 text-xs py-1 text-white">
            Claim
          </div>
        ) : null}
      </div>

      {/* Status Icon */}
      {showStatusIcon && (
        <div
          className={`
          absolute -bottom-16 left-1/2 transform -translate-x-1/2 z-10
          w-8 h-8 rounded-full flex items-center justify-center
          ${
            isCompleted
              ? "bg-status-completed-light dark:bg-status-completed-dark"
              : isActive
              ? "bg-brand-blue-1"
              : "bg-status-locked-light dark:bg-status-locked-dark"
          }
        `}
        >
          <Icon
            name={isCompleted ? "check-circle" : isLocked ? "lock" : "flag"}
            className={`w-4 h-4 ${
              isCompleted || isActive
                ? "text-icon-primary-dark"
                : "text-icon-primary-light dark:text-icon-secondary-dark"
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default TaskCard;
