import AccountInformation from "./components/AccountInformation";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";

const ProfilePage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex w-full p-8 bg-background-primary-light dark:bg-background-primary-dark">
      <AccountInformation />
      <div className="absolute bottom-0 left-1/2 mt-4 h-8 text-center text-xs text-gray-600 dark:text-gray-400">
        © {new Date().getFullYear()} Teneo. All rights reserved.
      </div>
    </div>
  );
};

export default ProfilePage;
