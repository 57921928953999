import { ComingSoonPage } from "../features/maintainance/comingSoonPage";
import { Toaster } from "react-hot-toast";
import AppRouter from "./router";
import { ThemeProvider } from "./context/ThemeContext";
import { WalletProvider } from "./context/WalletContext";
export default function AppWrapper() {
  if (process.env.REACT_APP_COMING_SOON === "true") {
    return <ComingSoonPage />;
  }

  return (
    <>
      <Toaster />
      <WalletProvider>
        <ThemeProvider>
          <AppRouter />
        </ThemeProvider>
      </WalletProvider>
    </>
  );
}
