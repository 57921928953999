import { useState, useEffect } from "react";
import { showToast } from "../../../../../components/common/Toast";
import { changeEmail } from "../../../../../app/data/api";
import { useAppContext } from "../../../../../app/context/AppContext";
import { useNavigate } from "react-router-dom";

interface ChangeEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangeEmailModal = ({ isOpen, onClose }: ChangeEmailModalProps) => {
  const { accessToken } = useAppContext();
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countdown, setCountdown] = useState<number | null>(null);

  useEffect(() => {
    if (countdown !== null && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      navigate("/auth");
    }
  }, [countdown, navigate]);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEmailError("");
    if (newEmail === "") {
      setEmailError("Email cannot be empty");
      return;
    }
    if (newEmail !== confirmEmail) {
      setEmailError("Emails do not match");
      return;
    }

    try {
      const response = await changeEmail(accessToken, newEmail);
      if (response?.data?.message === "Email updated successfully") {
        showToast({
          message: "Email changed successfully",
          type: "success",
        });
        setCountdown(5);
      } else {
        setEmailError(response.data.message);
      }
    } catch (error) {
      const errorMessage = "An error occurred while changing the email";
      setEmailError(errorMessage);
      showToast({ message: errorMessage, type: "error" });
      console.error("Email change error:", error);
    }
  };

  return (
    <div className="fixed inset-0 -translate-y-8 text-sm min-h-screen top-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-background-primary-light dark:bg-background-primary-dark w-full max-w-md p-8">
        {countdown !== null ? (
          <div className="text-center text-text-primary-light dark:text-text-primary-dark">
            <p className="mb-4">After changing your email please re-login.</p>
            <p>Logging you out in {countdown} seconds...</p>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-lg text-text-primary-light dark:text-text-primary-dark font-light">
                Change email
              </h2>
              <button
                onClick={onClose}
                className="text-text-primary-light dark:text-text-primary-dark opacity-50 hover:opacity-100"
              >
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block  text-text-primary-light dark:text-text-primary-dark mb-2">
                  Email
                </label>
                <div className="relative">
                  <input
                    value={newEmail}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                      setEmailError("");
                    }}
                    className="w-full p-4 border border-background-tertiary-light dark:border-background-tertiary-dark bg-background-primary-light dark:bg-background-primary-dark text-text-primary-light dark:text-text-primary-dark placeholder-text-secondary-light dark:placeholder-text-secondary-dark"
                    placeholder="Enter new email"
                  />
                </div>
              </div>

              <div>
                <label className="block  text-text-primary-light dark:text-text-primary-dark mb-2">
                  Confirm new email
                </label>
                <div className="relative">
                  <input
                    value={confirmEmail}
                    onChange={(e) => {
                      setConfirmEmail(e.target.value);
                      setEmailError("");
                    }}
                    className="w-full p-4 border border-background-tertiary-light dark:border-background-tertiary-dark bg-background-primary-light dark:bg-background-primary-dark text-text-primary-light dark:text-text-primary-dark placeholder-text-secondary-light dark:placeholder-text-secondary-dark"
                    placeholder="Confirm new email"
                  />
                </div>
              </div>

              {emailError && <div className="text-red-500 ">{emailError}</div>}

              <div className="flex justify-end space-x-4 mt-8">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-8 py-3  w-1/2 border border-background-tertiary-light dark:border-background-tertiary-dark text-text-primary-light dark:text-text-primary-dark hover:bg-background-tertiary-light dark:hover:bg-background-tertiary-dark "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-8 py-3 w-1/2 bg-secondary text-white hover:bg-opacity-90 "
                >
                  Change email
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ChangeEmailModal;
