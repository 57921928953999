import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../app/context/AppContext";
import { login as loginApi } from "../../../app/data/api";
import { EyeIcon, EyeOffIcon } from "../assets/logos";
export default function LoginPage() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { setAccessToken, setUser } = useAppContext();
  const [loading, setLoading] = useState(false);

  async function login(email: string, password: string) {
    setLoading(true);
    const response = await loginApi(email, password);
    if (response?.status === 200) {
      setAccessToken(response.data.access_token);
      setUser(response.data.user);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate("/dashboard");
    } else {
      setError("Invalid credentials");
    }
    setLoading(false);
  }

  return (
    <div className="flex text-sm flex-col items-center justify-center">
      <h1 className="text-[28px] text-[#1D1F23] font-normal mb-2">Login</h1>
      <p className="text-center text-sm text-[#3C3F40]  mb-8">
        to continue to your Teneo account.
      </p>

      <div className="space-y-6 w-full">
        <div>
          <label className="block text-sm mb-2">Email</label>
          <input
            type="email"
            value={email}
            onFocus={() => setError(null)}
            onChange={(e) => setEmail(e.target.value)}
            className={`w-full text-sm px-3 focus:outline-none py-2 border rounded ${"border-gray-300"}`}
            placeholder="Enter your email"
            required
          />
        </div>

        <div>
          <label className="block text-sm mb-2">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onFocus={() => setError(null)}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-3 py-2 border focus:outline-none rounded ${"border-gray-300"}`}
              placeholder="••••••••"
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOffIcon /> : <EyeIcon />}
            </button>
          </div>
          {error && <p className="text-[#A22628] text-sm mt-1">{error}</p>}
        </div>

        <div className="text-sm mb-4">
          <button
            type="button"
            onClick={() => {
              navigate("/auth/forgot-password");
            }}
            className="text-[#055E5E] hover:underline text-left"
          >
            Forgot your password?
          </button>
        </div>

        <button
          disabled={loading || !email || !password}
          onClick={() => login(email, password)}
          className="w-full bg-[#0044FF] text-white py-2  disabled:opacity-50"
        >
          {loading ? "Loading..." : "Sign In"}
        </button>

        <div className="text-center text-sm text-[#797E80]">
          Don't have an account?{" "}
          <span
            onClick={() => navigate("/auth/signup")}
            className="text-[#055E5E] cursor-pointer underline"
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
}
