import { TeneoLogo } from "features/auth/assets/logos";
import React from "react";

const MobileDisclaimer: React.FC = () => {
  return (
    <div className="flex p-6 bg-background-secondary-light dark:bg-background-secondary-dark items-center justify-center h-screen">
      <div className="text-center flex flex-col gap-4 items-center justify-center">
        <TeneoLogo className="w-28 mb-4" />
        <h1 className="text-2xl font-semibold mb-4">
          Mobile version
          <br /> coming soon
        </h1>
        <p className="text-lg">
          The dashboard has not yet been optimized for mobile devices. Please
          access the dashboard on a desktop computer for the best experience.
        </p>
      </div>
    </div>
  );
};

export default MobileDisclaimer;
