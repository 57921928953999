import React from "react";
import TaskCard from "./TaskCard";
import ProgressBar from "./ProgressBar";
export type TierType = "default" | "connectivity-streak";

export interface Tier {
  id: number;
  level: number;
  type?: TierType;
  icon: string;
  title: string;
  subtitle: string;
  pointsRequired: number;
  pointsReward: number;
  status: "claimable" | "claimed" | "locked";
  campaignId: string;
}

interface TiersWidgetProps {
  tiers: Tier[];
  currentPoints: number;
  onClaimReward: (tierId: string) => Promise<void>;
  className?: string;
  title?: string;
}

const TiersWidget: React.FC<TiersWidgetProps> = ({
  tiers,
  currentPoints,
  onClaimReward,
  className = "",
  title = "Referral Levels",
}) => {
  const displayTiers = tiers.slice(0, 5);
  const activeTierIndex = displayTiers.findIndex(
    (tier) => currentPoints < tier.pointsRequired
  );
  const progress =
    activeTierIndex === -1
      ? 100
      : (activeTierIndex / (displayTiers.length - 1)) * 100;

  return (
    <div
      className={`w-full bg-widget-background-light dark:bg-widget-background-dark p-6 ${className}`}
    >
      <h2 className="text-xl font-medium mb-6 text-text-primary-light dark:text-text-primary-dark">
        {title}
      </h2>

      <div className="relative w-full flex flex-col ">
        <div className="flex gap-2 justify-between">
          {displayTiers.map((tier) => {
            const isActive = tier.status === "claimable";
            const isLocked = tier.status === "locked";
            const isCompleted = tier.status === "claimed";

            return (
              <TaskCard
                key={tier.id}
                title={tier.title}
                subtitle={tier.subtitle}
                points={tier.pointsReward}
                isActive={isActive}
                isCompleted={isCompleted}
                isLocked={isLocked}
                showStatusIcon={true}
                iconType={tier.icon as TierType}
                onClick={async () => {
                  if (isActive) {
                    await onClaimReward(tier.campaignId);
                  }
                }}
              />
            );
          })}
        </div>

        <div className="w-full md:px-20 mt-12">
          <ProgressBar progress={progress} />
        </div>
      </div>
    </div>
  );
};

export default TiersWidget;
