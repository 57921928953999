import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LogoutPage() {
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const accessToken = localStorage.getItem("accessToken");

    if (!user && !accessToken) {
      setLoggingOut(true);
      setTimeout(() => {
        navigate("/auth");
        setLoggingOut(false);
      }, 785);
    }
  }, [navigate]);

  const handleLogout = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    setLoggingOut(true);
    setTimeout(() => {
      navigate("/auth");
      setLoggingOut(false);
    }, 785);
  };

  return (
    <div className="  text-sm h-ato top-0  flex items-center justify-center z-50">
      <div className=" dark:bg-background-primary dark:text-white  w-full max-w-md ">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-lg text-text-primary-light dark:text-text-primary font-light">
            Confirm Logout
          </h2>
          <button
            onClick={() => navigate("/dashboard")}
            className="text-text-primary-light dark:text-text-primary opacity-50 hover:opacity-100"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <p className="text-text-primary-light dark:text-text-primary mb-8">
          Are you sure you want to log out?
        </p>

        <div className="flex justify-end space-x-4 mt-8">
          <button
            onClick={() => navigate("/dashboard")}
            className="px-8 py-3 w-1/2 border border-background-tertiary-light dark:border-background-tertiary-dark text-text-primary-light dark:text-text-primary hover:bg-background-tertiary dark:hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleLogout}
            className="px-8 py-3 w-1/2 bg-secondary text-white hover:bg-opacity-90"
          >
            {loggingOut ? "Logging out..." : "Logout"}
          </button>
        </div>
      </div>
    </div>
  );
}
