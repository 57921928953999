import React, { useEffect } from "react";
import AppLayout from "../dashboard/layouts/AppLayout";
import { useAppContext } from "app/context/AppContext";

import { useNavigate } from "react-router-dom";

const ActivateDashboard: React.FC = () => {
  const { userOnboarded } = useAppContext();

  useEffect(() => {
    if (userOnboarded) {
      navigate("/dashboard");
    }
  }, [userOnboarded]);

  const navigate = useNavigate();
  return (
    <AppLayout title="Dashboard">
      <div className="flex flex-col items-center justify-center h-full p-6">
        <div className="max-w-full text-center space-y-6">
          <h1 className="text-[28px] text-[#1D1F23] font-normal">
            Thank you for joining Teneo!
          </h1>

          <p className="text-[#3C3F40] w-full text-lg">
            To activate your dashboard, click “Connect Node” in our extension,
            then refresh the page.
          </p>

          <div className="text-[#797E80]">
            Don’t have our extension yet? Download it here:{" "}
            <a
              href="https://bit.ly/teneo-community-node"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#055E5E] hover:underline"
            >
              bit.ly/teneo-community-node
            </a>
          </div>
          <button
            onClick={() => navigate("/dashboard")}
            className="bg-ticket-blue text-white px-6 py-3 hover:bg-opacity-80 transition-colors duration-200"
          >
            Refresh Page
          </button>
        </div>
      </div>
    </AppLayout>
  );
};

export default ActivateDashboard;
