import React, { useState, useEffect } from "react";
import { supabase } from "../../../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "../assets/logos";

export default function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const navigate = useNavigate();

  async function shouldRender() {
    const session = await supabase.auth.getSession();
    console.log(session);
    if (!session.data.session) {
      navigate("/auth");
    }
  }

  useEffect(() => {
    shouldRender();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      setLoading(false);
      return;
    }
    if (!/[A-Z]/.test(password)) {
      setError("Password must contain at least one uppercase letter");
      setLoading(false);
      return;
    }
    if (!/[a-z]/.test(password)) {
      setError("Password must contain at least one lowercase letter");
      setLoading(false);
      return;
    }
    if (!/\d/.test(password)) {
      setError("Password must contain at least one number");
      setLoading(false);
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: password,
      });
      if (error) {
        setError(error.message);
        setLoading(false);
        return;
      }
      setSuccess(true);
      await supabase.auth.signOut();
      setTimeout(() => navigate("/auth"), 1000);
    } catch (err: any) {
      setError(err.message || "An error occurred while resetting password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h1 className="text-[28px] text-[#1D1F23] font-normal mb-2">
          Reset Password
        </h1>
        <p className="text-[#3C3F40] text-sm">Enter your new password below.</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="password" className="block text-sm mb-2">
            New Password
          </label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              className="w-full px-3 py-2 border border-[#E5E7E8] focus:outline-none focus:ring-1 focus:ring-[#055E5E]"
              placeholder="Enter new password"
              required
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeIcon /> : <EyeOffIcon />}
            </button>
          </div>
          <div
            className={`transform transition-all duration-300 ease-in-out overflow-hidden ${
              isPasswordFocused
                ? "max-h-20 opacity-100 translate-y-0"
                : "max-h-0 opacity-0 -translate-y-2"
            }`}
          >
            <p className="mt-2 text-sm text-[#797E80]">
              Password needs at least 8 characters, 1 number and 1 uppercase
              letter.
            </p>
          </div>
        </div>

        <div>
          <label htmlFor="confirmPassword" className="block text-sm mb-2">
            Confirm Password
          </label>
          <input
            id="confirmPassword"
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border border-[#E5E7E8] focus:outline-none focus:ring-1 focus:ring-[#055E5E]"
            placeholder="Confirm new password"
            required
          />
        </div>

        {error && (
          <div className="p-3 text-sm text-[#A22628] bg-[#FDE8E8]">{error}</div>
        )}

        {success && (
          <div className="p-3 text-sm text-[#055E5E] bg-[#D3E6E4]">
            Password has been reset successfully. Redirecting to login...
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-[#0044FF] text-white py-2 hover:bg-opacity-90 transition-colors disabled:opacity-50"
        >
          {loading ? "Resetting..." : "Reset Password"}
        </button>

        <div className="text-center text-sm">
          <button
            type="button"
            onClick={() => navigate("/auth")}
            className="text-[#055E5E] hover:underline"
          >
            Back to Login
          </button>
        </div>
      </form>
    </div>
  );
}
