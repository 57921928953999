import { ReactComponent as CopyIcon } from './svg/copy.svg';
import { ReactComponent as DashboardIcon } from './svg/dashboard.svg';
import { ReactComponent as GiftIcon } from './svg/gift.svg';
import { ReactComponent as StarIcon } from './svg/star.svg';
import { ReactComponent as ProfileIcon } from './svg/profile.svg';
import { ReactComponent as LogoutIcon } from './svg/logout.svg';
import { ReactComponent as ChevronRightIcon } from './svg/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from './svg/chevron-left.svg';
import { ReactComponent as ChevronDownIcon } from './svg/chevron-down.svg';
import { ReactComponent as ArrowIcon } from './svg/arrow.svg';
import { ReactComponent as CheckIcon } from './svg/check.svg';
import { ReactComponent as HeartBeatIcon } from './svg/heart-beat.svg';
import { ReactComponent as InfoIcon } from './svg/info.svg';
import { ReactComponent as MoonIcon } from './svg/moon.svg';
import { ReactComponent as SunIcon } from './svg/sun.svg';
import { ReactComponent as TrendUpIcon } from './svg/trend-up.svg';
import { ReactComponent as TrendDownIcon } from './svg/trend-down.svg';
import { ReactComponent as FileTextIcon } from './svg/file-text.svg';
import { ReactComponent as PlusIcon } from './svg/plus.svg';
import { ReactComponent as MinusIcon } from './svg/minus.svg';
import { ReactComponent as RocketIcon } from './svg/rocket.svg';
import { ReactComponent as ShareNetworkIcon } from './svg/share-network.svg';
import { ReactComponent as StarFourIcon } from './svg/star-four.svg';
import { ReactComponent as LockIcon } from './svg/lock.svg';
import { ReactComponent as CheckCircleIcon } from './svg/check-circle.svg';
import { ReactComponent as FlagIcon } from './svg/flag.svg';
import { ReactComponent as WalletIcon } from './svg/wallet.svg';
import { ReactComponent as UserPlusIcon } from './svg/user-plus.svg';
import { ReactComponent as XLogoIcon } from './svg/x-logo.svg';
import { ReactComponent as DiscordIcon } from './svg/discord.svg';
import { ReactComponent as ClockCountdownIcon } from './svg/clock-countdown.svg';
import { ReactComponent as FlameIcon } from './svg/flame.svg';
import { ReactComponent as HandGrabblingIcon } from './svg/hand-grabbling.svg';
const icons = {
  copy: CopyIcon,
  dashboard: DashboardIcon,
  gift: GiftIcon,
  star: StarIcon,
  profile: ProfileIcon,
  logout: LogoutIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-down': ChevronDownIcon,
  arrow: ArrowIcon,
  check: CheckIcon,
  'heart-beat': HeartBeatIcon,
  info: InfoIcon,
  moon: MoonIcon,
  sun: SunIcon,
  'trend-up': TrendUpIcon,
  'trend-down': TrendDownIcon,
  'file-text': FileTextIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  rocket: RocketIcon,
  'share-network': ShareNetworkIcon,
  'star-four': StarFourIcon,
  lock: LockIcon,
  'check-circle': CheckCircleIcon,
  flag: FlagIcon,
  wallet: WalletIcon,
  'user-plus': UserPlusIcon,
  'x-logo': XLogoIcon,
  discord: DiscordIcon,
  'clock-countdown': ClockCountdownIcon,
  flame: FlameIcon,
  'hand-grabbling': HandGrabblingIcon,
} as const;

export type IconName = keyof typeof icons;

interface IconProps {
  name: IconName;
  className?: string;
  size?: number;
  color?: string;
  onClick?: () => void;
}

export const Icon = ({ 
  name, 
  className, 
  size = 24, 
  color = 'currentColor',
  onClick 
}: IconProps) => {
  const IconComponent = icons[name];
  
  return (
    <IconComponent 
      width={size} 
      height={size} 
      className={className}
      fill={color}
      onClick={onClick}
    />
  );
};
