import { getUser } from "app/data/api";
import { useState, createContext, useContext } from "react";

type AppContextType = {
  [key: string]: any;
};

export const AppContext = createContext<AppContextType>({});

export function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [userOnboarded, setUserOnboarded] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [user, setUser] = useState<any>(localStorage.getItem("user"));
  const [userStats, setUserStats] = useState<any>(null);

  async function refreshUserData() {
    const userResponse = await getUser();
    setUser(userResponse.data);
    localStorage.setItem("user", JSON.stringify(userResponse.data));
  }

  const contextValue: AppContextType = {
    accessToken,
    setAccessToken,
    user,
    setUser,
    userStats,
    setUserStats,
    userOnboarded,
    setUserOnboarded,
    refreshUserData,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
