import { supabase } from "utils/supabaseClient";
import { useState, useEffect } from "react";
import { onboardUser } from "app/data/api";
import { useNavigate } from "react-router-dom";

export default function VerifyEmailPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userData, setUserData] = useState("");
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleTokenLogin = async () => {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const access_token = params.get("access_token");
      const refresh_token = params.get("refresh_token");

      if (!access_token || !refresh_token) {
        setError("No token provided");
        return;
      }

      try {
        setLoading(true);

        const { data, error } = await supabase.auth.setSession({
          access_token,
          refresh_token,
        });
        console.log(data, error);
        if (data?.user?.id) {
          await onboardUser(data.user?.id);
          setUserData(data.user.id);
          setVerified(true);
        }
        if (error) throw error;
      } catch (err: any) {
        setError(err?.message);
      } finally {
        setLoading(false);
      }
    };

    handleTokenLogin();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div> {error}</div>;

  if (userData) {
    if (verified) {
      return (
        <div className="flex flex-col items-center justify-center">
          <div>Verified</div>
          <button
            className="bg-blue-500 text-white px-4 py-2 mt-4 hover:bg-blue-600"
            onClick={() => navigate("/auth")}
          >
            Login
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-2xl font-semibold mb-4">Verify Your Email</h2>
          <p className="text-gray-600 mb-8 text-center">
            Click button below to verify your email
          </p>
          <button
            className="bg-blue-500 text-white px-4 py-2  hover:bg-blue-600"
            onClick={() => window.location.reload()}
          >
            Verify My Email
          </button>
        </div>
      );
    }
  } else {
    return <div>Loading...</div>;
  }
}
