import React, { useState } from "react";
import { supabase } from "../../../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import { checkIfUserExists } from "app/data/api";

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const trimmedEmail = email.trim();

    const alreadyExists = await checkIfUserExists(trimmedEmail);
    if (!alreadyExists) {
      setError("No user found with this email");
      setLoading(false);
      return;
    }

    try {
      const url = window.location.origin;
      const { error } = await supabase.auth.resetPasswordForEmail(
        trimmedEmail,
        {
          redirectTo: `${url}/auth/reset-password`,
        }
      );

      if (error) setError(error.message);
      setSuccess(true);
      setEmail("");
    } catch (err: any) {
      setError(err.message || "An error occurred while sending the reset link");
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  return (
    <div className="space-y-6">
      <div className="text-center">
        <h1 className="text-[28px] text-[#1D1F23] font-normal mb-2">
          Forgot Password
        </h1>
        <p className="text-[#3C3F40] text-sm">
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm mb-2">
            Email Address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-[#E5E7E8] focus:outline-none focus:ring-1 focus:ring-[#055E5E]"
            placeholder="Enter your email"
            required
          />
        </div>

        {error && (
          <div className="p-3 text-sm text-[#A22628] bg-[#FDE8E8]">{error}</div>
        )}

        {success && (
          <div className="p-3 text-sm text-[#055E5E] bg-[#D3E6E4] ">
            Password reset link has been sent to your email.
          </div>
        )}

        <button
          type="submit"
          disabled={loading || !email}
          className="w-full bg-[#0044FF] text-white py-2  hover:bg-opacity-90 transition-colors disabled:opacity-50"
        >
          {loading ? "Sending..." : "Send Reset Link"}
        </button>

        <div className="text-center text-sm">
          <button
            type="button"
            onClick={() => navigate("/auth")}
            className="text-[#055E5E] hover:underline"
          >
            Back to Login
          </button>
        </div>
      </form>
    </div>
  );
}
