import React from "react";

interface DashboardGridProps {
  children: React.ReactNode;
  className?: string;
}

interface DashboardSectionProps {
  children: React.ReactNode;
  className?: string;
}

export const DashboardSection: React.FC<DashboardSectionProps> = ({
  children,
  className = "",
}) => {
  return <div className={`grid gap-6 ${className}`}>{children}</div>;
};

export const DashboardGrid: React.FC<DashboardGridProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`flex-1 p-6 space-y-6 overflow-y-auto min-h-0 h-full ${className}`}
    >
      {children}
    </div>
  );
};
