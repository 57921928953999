import React, { useEffect, useState } from "react";
import { showToast } from "../../../../components/common/Toast";
import BonusRewards from "./components/BonusRewards";
import { Task } from "../../../../types/task";
import { useWallet } from "../../../../app/context/WalletContext";
import { useAppContext } from "../../../../app/context/AppContext";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";
import TiersWidget from "../referrals/components/TiersWidget";
import { useCampaigns } from "../../../../hooks/useCampaigns";
import { useReferrals } from "../../../../hooks/useReferrals";
import { formatNumber } from "../../../../utils/helper";

interface Campaign {
  campaignName: string;
  campaignId: string;
  type: string;
  formUrl: string;
  bonusPoints: number;
  completed: boolean;
  mandatory?: boolean;
  submitted?: boolean;
}

const RewardsPage: React.FC = () => {
  const [verificationCampaigns, setVerificationCampaigns] = useState<
    Campaign[]
  >([]);

  const [tasks, setTasks] = useState<Task[]>([]);
  const { user } = useAppContext();
  const { campaigns: referralCampaigns, claimReward: claimReferralReward } =
    useCampaigns("referral");
  const {
    campaigns: connectivityCampaigns,
    claimReward: claimConnectivityReward,
  } = useCampaigns("heartbeat");
  console.log(connectivityCampaigns);
  const [agreed, setAgreed] = useState(true);
  const { linkWallet } = useWallet();
  const referralTiers = referralCampaigns
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((campaign) => ({
      id: campaign.tier_id,
      level: campaign.tier_id,
      icon: "rocket",
      title: campaign.title,
      subtitle: campaign.subtitle,
      pointsRequired: campaign.points_required,
      pointsReward: campaign.points_reward,
      status: campaign.status,
      campaignId: campaign.id,
    }));

  console.log(connectivityCampaigns);

  const connectivityTiers = connectivityCampaigns
    .map((campaign) => ({
      id: campaign.tier_id,
      level: campaign.tier_id,
      icon: "connectivity-streak",
      title: campaign.title,
      subtitle: campaign.subtitle,
      pointsRequired: campaign.points_required,
      pointsReward: campaign.points_reward,
      status: campaign.status,
      campaignId: campaign.id,
    }))
    .sort((a, b) => a.pointsRequired - b.pointsRequired);

  const { stats } = useReferrals();

  const [pendingTasks, setPendingTasks] = useState<Set<string>>(new Set());

  useEffect(() => {
    const savedPendingTasks = localStorage.getItem('pendingTasks');
    if (savedPendingTasks) {
      setPendingTasks(new Set(JSON.parse(savedPendingTasks)));
    }
  }, []);

  const transformCampaignsToTasks = (campaigns: Campaign[]): Task[] => {
    return campaigns.map((campaign, index) => ({
      type: campaign.type,
      id: campaign.campaignId,
      taskNumber: index + 1,
      title: campaign.campaignName,
      points: campaign.bonusPoints,
      status: campaign.completed 
        ? "completed" 
        : pendingTasks.has(campaign.campaignId)
          ? "pending"
          : "pending" as const,
      action:
        !campaign.completed && !pendingTasks.has(campaign.campaignId)
          ? {
              label: campaign.type === "wallet" 
                ? "Link now"
                : index === 1 
                  ? "Engage now" 
                  : index === 2 
                    ? "Connect now" 
                    : "Start",
              url: campaign.formUrl,
            }
          : undefined,
      mandatory: campaign.mandatory,
    }));
  };
  const [loading, setLoading] = useState(true);
  const { accessToken } = useAppContext();
  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submissions/campaigns`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setVerificationCampaigns(data);
      setTasks(transformCampaignsToTasks(data));
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleTaskAction = async (taskId: string, actionType?: "claim") => {
    const task = tasks.find((t) => t.id === taskId);
    if (!task) return;

    const campaign = verificationCampaigns.find((c) => c.campaignId === taskId);
    if (!campaign) return;

    if (campaign.type === "wallet") {
      await linkWallet();
      fetchCampaigns();
      return;
    }

    if (!user?.wallet) {
      showToast({
        message: "Please link your wallet first",
        type: "error",
      });
      return;
    }

    /* Commenting out claim functionality as it's handled automatically now
    if (actionType === "claim") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/submissions/process?campaignId=${taskId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          // showToast({
          //   message: `+${formatNumber(campaign.bonusPoints)} PTS`,
          //   type: "success",
          // });
          const responseData = await response.json();
          showToast({
            message: responseData.message,
            type: "success",
          });
          fetchCampaigns();
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to claim rewards");
        }
      } catch (error) {
        console.error("Error claiming rewards:", error);
        showToast({
          message:
            error instanceof Error ? error.message : "Failed to claim rewards",
          type: "error",
        });
      }
    } else {
    */
    if (task.action?.url) {
      window.open(task.action.url, "_blank");
      const newPendingTasks = new Set(pendingTasks).add(taskId);
      setPendingTasks(newPendingTasks);
      localStorage.setItem('pendingTasks', JSON.stringify(Array.from(newPendingTasks)));
      // Force refresh tasks
      setTasks(transformCampaignsToTasks(verificationCampaigns));
    }
    // }  // Closing brace for commented out else block
  };

  const handleClaimReward = async (campaignId: string) => {
    try {
      const success = await claimReferralReward(campaignId);
      if (success) {
        showToast({
          message: "Reward claimed successfully",
          type: "success",
        });
      }
    } catch (error) {
      showToast({
        message: "Failed to claim reward",
        type: "error",
      });
    }
  };

  const handleConnectivityClaimReward = async (campaignId: string) => {
    try {
      const success = await claimConnectivityReward(campaignId);
      if (success) {
        showToast({
          message: "Reward claimed successfully",
          type: "success",
        });
      }
    } catch (error) {
      showToast({
        message: "Failed to claim reward",
        type: "error",
      });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="p-8 bg-background-primary-light dark:bg-background-primary-dark ">
      <div className="w-full gap-4 flex flex-col items-center justify-center">
        <BonusRewards
          tasks={tasks}
          onTaskAction={handleTaskAction}
          agreed={agreed}
          onAgreeChange={setAgreed}
        />

        <TiersWidget
          title="Connectivity Streaks"
          tiers={connectivityTiers}
          currentPoints={stats.totalReferralPoints}
          onClaimReward={handleConnectivityClaimReward}
          className="bg-background-secondary-light dark:bg-background-secondary-dark p-8 shadow-sm"
        />
        <TiersWidget
          tiers={referralTiers}
          currentPoints={stats.totalReferralPoints}
          onClaimReward={handleClaimReward}
          className="bg-background-secondary-light dark:bg-background-secondary-dark p-8 shadow-sm"
        />
        <div className=" -mb-4 text-center text-xs text-gray-600 dark:text-gray-400">
          © {new Date().getFullYear()} Teneo. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default RewardsPage;
