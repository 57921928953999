import React from "react";
import { Icon } from "../../../../../components/icons/Icon";

interface StepProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Step: React.FC<StepProps> = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center">
    <div className="mb-4">
      <div className="w-12 h-12 bg-secondary rounded-lg flex items-center justify-center">
        <div className="text-white">{icon}</div>
      </div>
    </div>
    <h3 className="text-lg font-medium mb-2 text-text-primary-light dark:text-text-primary-dark">
      {title}
    </h3>
    <p className="text-text-secondary-light dark:text-text-secondary-dark text-sm">
      {description}
    </p>
  </div>
);

const ReferralSteps: React.FC = () => {
  return (
    <div className="mb-8 bg-background-secondary-light dark:bg-background-secondary-dark p-6">
      <h2 className="text-xl font-medium mb-6 text-text-primary-light dark:text-text-primary-dark">
        How It Works
      </h2>
      <div className="border border-background-divide-light dark:border-background-divide-dark grid grid-cols-1 md:grid-cols-3 gap-8 divide-y md:divide-y-0 md:divide-x divide-background-divide-light dark:divide-background-divide-dark">
        <div className="p-4 md:p-4 md:px-6">
          <Step
            icon={<Icon name="share-network" />}
            title="Share your invite link"
            description="Invite your friends in order to boost your rewards."
          />
        </div>
        <div className="p-6 md:p-4 md:px-6">
          <Step
            icon={<Icon name="star-four" />}
            title="Your friends join Teneo"
            description="Your friends receive 1,000 Teneo Points right away when they join."
          />
        </div>
        <div className="p-6 md:p-4 md:px-6">
          <Step
            icon={<Icon name="gift" />}
            title="Boost rewards"
            description="You get 2,000 Teneo Points when invitee completed 100 heartbeats."
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralSteps;
