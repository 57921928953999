import React from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
const AppLayout: React.FC<{ children: React.ReactNode; title?: string }> = ({
  children,
  title = "Dashboard",
}) => {
  return (
    <div className="app-container h-screen flex overflow-hidden">
      <Sidebar />
      <div className="main-wrapper flex-1 flex flex-col">
        <Header title={title} />
        <div className="main-content flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
