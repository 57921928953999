import React from "react";
import TableWidget from "../../components/TableWidget";
import StatWidget from "../../components/StatWidget";
import ReferralSteps from "./components/ReferralSteps";
import ReferralLink from "./components/ReferralLink";
import { useReferrals } from "../../../../hooks/useReferrals";
import { useCampaigns } from "../../../../hooks/useCampaigns";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";
import TiersWidget from "./components/TiersWidget";
import { showToast } from "../../../../components/common/Toast";
import { claimReferral } from "../../../../app/data/api";

const ReferralsPage: React.FC = () => {
  const { referrals, stats, loading: referralsLoading, refetch } = useReferrals();
  const {
    campaigns,
    loading: campaignsLoading,
    claimReward,
  } = useCampaigns("referral");

  const referralTiers = campaigns
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((campaign) => ({
      id: campaign.tier_id,
      level: campaign.tier_id,
      icon: "rocket",
      title: campaign.title,
      subtitle: campaign.subtitle,
      pointsRequired: campaign.points_required,
      pointsReward: campaign.points_reward,
      status: campaign.status,
      campaignId: campaign.id,
    }));

  const handleClaimReward = async (campaignId: string) => {
    const success = await claimReward(campaignId);
    if (success) {
      showToast({
        message: "Reward claimed successfully",
        type: "success",
      });
    } else {
      showToast({
        message: "Failed to claim reward",
        type: "error",
      });
    }
  };

  const handleClaimReferral = async (referralId: string) => {
    try {
      const response = await claimReferral(referralId);
      
      if (response?.data?.success) {
        showToast({
          message: "Referral points claimed successfully!",
          type: "success",
        });
        
        await refetch();
      } else {
        showToast({
          message: "Failed to claim referral points",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error claiming referral:", error);
      showToast({
        message: "Failed to claim referral points",
        type: "error",
      });
    }
  };

  if (referralsLoading || campaignsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="h-full p-4">
      <ReferralSteps />
      <ReferralLink />
      <div className="mb-8">
        <TiersWidget
          title="Referral Levels"
          tiers={referralTiers}
          currentPoints={stats.totalReferralPoints}
          onClaimReward={handleClaimReward}
          className="bg-background-secondary-light dark:bg-background-secondary-dark p-8 shadow-sm"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <StatWidget
          title="Successful Referrals"
          value={stats.successfulReferralsAmount}
          className="bg-background-secondary-light dark:bg-background-secondary-dark p-6 shadow-sm"
          timeFrame="All time"
        />
        <StatWidget
          title="Pending Referrals"
          value={stats.pendingReferralsAmount}
          className="bg-background-secondary-light dark:bg-background-secondary-dark p-6 shadow-sm"
          timeFrame="All time"
        />
        <StatWidget
          title="Total Referral Points"
          value={stats.totalReferralPoints}
          className="bg-background-secondary-light dark:bg-background-secondary-dark p-6 shadow-sm"
          timeFrame="All time"
        />
      </div>

      <div className="bg-background-secondary-light dark:bg-background-secondary-dark shadow-sm">
        <TableWidget
          limit={1000}
          data={referrals}
          className="w-full"
          onClaimReferral={handleClaimReferral}
        />
      </div>
      <div className=" mt-4 h-8 text-center text-xs text-gray-600 dark:text-gray-400">
        © {new Date().getFullYear()} Teneo. All rights reserved.
      </div>
    </div>
  );
};

export default ReferralsPage;
