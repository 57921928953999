import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AppLayout from "../features/dashboard/layouts/AppLayout";
import Dashboard from "../features/dashboard/pages/dashboard/dashboardPage";
import ReferralsPage from "../features/dashboard/pages/referrals/ReferralsPage";
import RewardsPage from "../features/dashboard/pages/rewards/rewardsPage";
import FAQs from "../features/dashboard/pages/faq/faqPage";
import AuthRouter from "../features/auth/authRouter";
import { useAppContext } from "./context/AppContext";
import ProfilePage from "../features/dashboard/pages/profile/profilePage";
import ActivateDashboard from "features/maintainance/activate-dashboard";
import { getUser } from "./data/api";

export default function AppRouter() {
  const accessToken = localStorage.getItem("accessToken");

  const { setAccessToken, setUser } = useAppContext();
  const navigator = useNavigate();
  const currentPath = useLocation().pathname;
  const pathsToByPassAuth = [
    "/auth/reset-password",
    "/auth/logout",
    "/auth/forgot-password",
    "/auth/verify",
  ];

  const guardCheck = async () => {
    if (pathsToByPassAuth.includes(currentPath)) {
      return;
    }
    if (!accessToken) {
      navigator("/auth");
    } else {
      setAccessToken(accessToken);
      const userResponse = await getUser();
      setUser(userResponse.data);
      localStorage.setItem("user", JSON.stringify(userResponse.data));
      if (currentPath === "/faqs") {
        navigator("/faqs");
      }
      if (currentPath === "/rewards") {
        navigator("/rewards");
      }
      if (currentPath === "/referrals") {
        navigator("/referrals");
      } else {
        navigator("/dashboard");
      }
    }
  };

  useEffect(() => {
    guardCheck();
  }, []);

  return (
    <main className="main-content bg-background-primary-light dark:bg-background-primary-dark min-h-screen w-full">
      <Routes>
        <Route path="/activate" element={<ActivateDashboard />} />{" "}
        <Route
          path="/dashboard"
          element={
            <AppLayout title="Dashboard">
              <Dashboard />
            </AppLayout>
          }
        />
        <Route
          path="/referrals"
          element={
            <AppLayout title="Your Referrals">
              <ReferralsPage />
            </AppLayout>
          }
        />
        <Route
          path="/rewards"
          element={
            <AppLayout title="Rewards">
              <RewardsPage />
            </AppLayout>
          }
        />
        <Route
          path="/profile"
          element={
            <AppLayout title="Profile">
              <ProfilePage />
            </AppLayout>
          }
        />
        <Route
          path="/faqs"
          element={
            <AppLayout title="Help & FAQs">
              <FAQs />
            </AppLayout>
          }
        />
        <Route path="/auth/*" element={<AuthRouter />} />
        <Route path="/" element={<AuthRouter />} />
      </Routes>
    </main>
  );
}
