import { TeneoIcon } from "./logos";

export const BackgroundElements = () => {
  return (
    <div className="absolute inset-0 grid grid-cols-8" aria-hidden="true">
      {Array.from({ length: 8 }).map((_, i) => (
        <div key={i} className="border-l border-[#9d9d9d] h-full" />
      ))}
    </div>
  );
};
