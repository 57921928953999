import React from "react";
import { Icon } from "../../../components/icons";
import { formatNumber } from "../../../utils/helper";

interface StatWidgetProps {
  title: string;
  value: string | number;
  unit?: string;
  comparison?: {
    value: number;
    period: string;
    trend: "up" | "down";
  };
  timeFrame?: string;
  className?: string;
}

const StatWidget: React.FC<StatWidgetProps> = ({
  title,
  value,
  unit,
  comparison,
  timeFrame,
  className = "",
}) => {
  return (
    <div className={`${className} p-6`}>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-gray-600 dark:text-gray-400 text-sm">{title}</h3>
        {timeFrame && (
          <span className="text-gray-400 text-xs">{timeFrame}</span>
        )}
      </div>

      <div className="flex items-end gap-2">
        <span className="text-3xl font-semibold dark:text-white">
          {formatNumber(value)}
        </span>
        {unit && (
          <span className="text-gray-500 dark:text-gray-400 mb-1">{unit}</span>
        )}
      </div>

      {comparison && (
        <div className="flex items-center gap-2 mt-2">
          <div
            className={`flex items-center gap-1 px-2 py-1  text-xs ${
              comparison.trend === "up"
                ? "bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400"
                : "bg-red-50 text-red-600 dark:bg-red-900/20 dark:text-red-400"
            }`}
          >
            <Icon
              name={comparison.trend === "up" ? "trend-up" : "trend-down"}
              size={16}
            />
            <span>{formatNumber(comparison.value)}%</span>
          </div>
          <span className="text-gray-500 dark:text-gray-400 text-xs">
            compared to {comparison.period}
          </span>
        </div>
      )}
    </div>
  );
};

export default StatWidget;
