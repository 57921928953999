import React, { createContext, useContext, useCallback, useState } from "react";
import { ethers } from "ethers";
import { showToast } from "../../components/common/Toast";
import { useAppContext } from "./AppContext";
import { Modal } from "../../components/common/Modal";

interface WalletContextType {
  isWalletInstalled: boolean;
  linkWallet: () => Promise<void>;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, accessToken, setUser } = useAppContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const detectEthereumProvider = () => {
    const { ethereum } = window as Window & {
      ethereum?: {
        isMetaMask?: boolean;
        isPhantom?: boolean;
        request: (args: { method: string; params?: any[] }) => Promise<any>;
        on: (event: string, callback: (params: any) => void) => void;
        removeListener: (event: string, callback: (params: any) => void) => void;
      };
    };
    return !!ethereum && (
      ethereum.isMetaMask ||
      ethereum.isPhantom
    );
  };

  const linkWallet = useCallback(async () => {
    try {
      if (!window.ethereum) {
        showToast({
          message: "Please install MetaMask or Phantom wallet",
          type: "error",
        });
        return;
      }

      if (!(window as any).ethereum.isMetaMask && !(window as any).ethereum.isPhantom) {
        showToast({
          message: "Please use either MetaMask or Phantom wallet",
          type: "error",
        });
        return;
      }

      setShowConfirmModal(true);
    } catch (err: any) {
      console.error("Wallet linking error:", err);
      showToast({
        message: "An error occurred while linking your wallet.",
        type: "error",
      });
    }
  }, []);

  const handleWalletLinking = async () => {
    try {
      if (!accessToken || !user?.id) {
        showToast({
          message: "You must be logged in to link a wallet",
          type: "error",
        });
        return;
      }

      const provider = new ethers.BrowserProvider(
        (window as Window & { ethereum?: ethers.Eip1193Provider }).ethereum as ethers.Eip1193Provider
      );
      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      const message = `Permanently link wallet to Teneo account: ${user.email} This can only be done once.`;
      const signature = await signer.signMessage(message);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/link-wallet`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            address,
            signature,
            message,
          }),
        }
      );

      const data = await response.json();

      if (!data.success) {
        showToast({
          message: data.message || "Failed to link wallet",
          type: "error",
        });
        return;
      }

      if (data.user) {
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user);
      }

      showToast({
        message: "Wallet linked successfully!",
        type: "success",
      });
    } catch (err: any) {
      console.error("Wallet linking error:", err);

      if (err.message.includes("404")) {
        showToast({
          message: "Service unavailable. Please try again later.",
          type: "error",
        });
      } else if (err.message.includes("Unexpected token")) {
        showToast({
          message: "Unexpected response from server. Please contact support.",
          type: "error",
        });
      } else {
        showToast({
          message: "An error occurred while linking your wallet.",
          type: "error",
        });
      }
    }
  };

  return (
    <WalletContext.Provider
      value={{
        isWalletInstalled: !!detectEthereumProvider(),
        linkWallet,
      }}
    >
      {showConfirmModal && (
        <Modal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          title="Link Wallet"
          onConfirm={async () => {
            setShowConfirmModal(false);
            await handleWalletLinking();
          }}
          confirmText="Proceed"
          cancelText="Cancel"
        >
          <p className="text-gray-700 dark:text-gray-300">
            You are about to link either MetaMask or Phantom wallet to your account.
            <br /><br />
            <strong>Please note:</strong>
            <ul className="list-disc pl-5 mt-2">
              <li>This action cannot be undone</li>
              <li>The wallet cannot be changed later</li>
              <li>Only MetaMask or Phantom wallets are supported</li>
            </ul>
          </p>
        </Modal>
      )}
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
