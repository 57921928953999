import { useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import { useAppContext } from "../../../../../app/context/AppContext";
import ChangeEmailModal from "./ChangeEmailModal";
import ConnectWallet from "./ConnectWallet";
import { useNavigate } from "react-router-dom";
const AccountInformation = () => {
  const { user } = useAppContext();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-background-secondary-light dark:bg-background-secondary-dark p-6 w-full shadow-sm">
        <h2 className="text-2xl font-normal mb-6 text-text-primary-light dark:text-text-primary-dark">
          Account Information
        </h2>
        <div className="space-y-4 flex flex-col  items-start justify-start">
          <div className="flex items-start flex-col">
            <label className="text-text-secondary-light dark:text-text-secondary-dark">
              Email
            </label>
            <div className="flex items-center gap-2">
              <span className="text-text-primary-light dark:text-text-primary-dark">
                {user?.email}
              </span>
            </div>
          </div>
          <div>
            <div className="text-sm mb-2 text-text-secondary-light dark:text-text-secondary-dark">
              Wallet
            </div>
            {user?.wallet ? (
              <div className="text-text-primary-light  dark:text-text-primary-dark">
                {user.wallet}
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <button
                  className={`px-4 py-2 mb-4 text-white transition-colors ${
                    true
                      ? "bg-blue-600 hover:bg-blue-700"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                  onClick={() => navigate("/rewards")}
                  disabled={false}
                >
                  Link Wallet
                </button>
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <button
              onClick={() => setIsPasswordModalOpen(true)}
              className="dark:text-white w-44 hover:text-opacity-80 px-4 py-2 border border-background-tertiary-light dark:border-background-tertiary-dark "
            >
              Change password
            </button>

            {/* DO NOT DELETE THIS COMMENT. WE NEED TO LAUNCH AFTER 1 WEEK */}

            {/* <button
              onClick={() => setIsEmailModalOpen(true)}
              className="dark:text-white w-44 hover:text-opacity-80 px-4 py-2 border border-background-tertiary-light dark:border-background-tertiary-dark  "
            >
              Change email
            </button> */}

            {/* delete account button. mailto:support@getpoints.com */}

            <button
              className="text-red-500 w-44 hover:text-opacity-80 px-4 py-1.5 border-2 border-red-200  dark:border-background-tertiary-dark "
              onClick={() => window.open("mailto:support@teneo.pro")}
            >
              Delete account
            </button>
          </div>
        </div>
      </div>

      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />
      <ChangeEmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
      />
    </>
  );
};

export default AccountInformation;
