import React from "react";
import { formatNumber } from "../../../../../utils/helper";

interface PointsBreakdown {
  category: string;
  percentage: number;
  color: string;
}

interface GaugeWidgetProps {
  title: string;
  totalPoints: number;
  breakdown: PointsBreakdown[];
  className?: string;
}

const GaugeWidget: React.FC<GaugeWidgetProps> = ({
  title,
  totalPoints,
  breakdown,
  className = "",
}) => {
  return (
    <div className={`${className}`}>
      <h3 className="text-gray-600 dark:text-gray-400 text-sm mb-6">{title}</h3>

      <div className="relative w-auto h-[300px] mx-auto -mt-16 mb-8">
        {/* Semi-circular gauge */}
        <div className="absolute inset-0">
          <svg className="w-full h-full" viewBox="0 0 600 300">
            {breakdown.map((item, index, arr) => {
              const startAngle = arr
                .slice(0, index)
                .reduce((sum, curr) => sum + (curr.percentage * 180) / 100, 0);
              const angle = (item.percentage * 180) / 100;
              const x1 =
                300 + 200 * Math.cos(((startAngle - 180) * Math.PI) / 180);
              const y1 =
                300 + 200 * Math.sin(((startAngle - 180) * Math.PI) / 180);
              const x2 =
                300 +
                200 * Math.cos(((startAngle + angle - 180) * Math.PI) / 180);
              const y2 =
                300 +
                200 * Math.sin(((startAngle + angle - 180) * Math.PI) / 180);
              const largeArcFlag = angle > 180 ? 1 : 0;

              return (
                <path
                  key={item.category}
                  d={`M ${x1} ${y1} A 200 200 0 ${largeArcFlag} 1 ${x2} ${y2}`}
                  fill="none"
                  stroke={item.color}
                  strokeWidth="40"
                  className="transition-all duration-300"
                />
              );
            })}
          </svg>
        </div>

        {/* Bottom text */}
        <div className="absolute inset-x-0 bottom-0 flex flex-col items-center">
          <span className="text-5xl font-semibold dark:text-white">
            {formatNumber(totalPoints)}
          </span>
          <span className="text-xl text-gray-500 dark:text-gray-400">
            Total Points
          </span>
        </div>
      </div>

      {/* Legend */}
      <div className="grid grid-cols-2 gap-4">
        {breakdown.map((item) => (
          <div key={item.category} className="flex items-center gap-2">
            <div className="w-3 h-3 " style={{ backgroundColor: item.color }} />
            <span className="text-sm text-gray-600 dark:text-gray-300">
              {item.category}
            </span>
            <span className="text-sm text-gray-500 dark:text-gray-400 ml-auto">
              {item.percentage}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GaugeWidget;
