import ForgotPasswordPage from "./pages/forgetPasswordPage";
import ResetPasswordPage from "./pages/resetPasswordPage";
import SignupPage from "./pages/signupPage";
import LoginPage from "./pages/loginPage";
import { Route, Routes } from "react-router-dom";
import AuthHeader from "./components/header";
import { BackgroundElements } from "./assets/elements";
import LogoutPage from "./pages/logoutPage";
import VerifyEmailPage from "./pages/verifyEmalPage";

export default function AuthRouter() {
  return (
    <div className="min-h-screen  bg-[#BAD3D8] items-center justify-center flex flex-col relative overflow-hidden">
      <AuthHeader />
      <div className="flex-1 flex items-center  justify-center">
        <BackgroundElements />
        <div className="bg-white p-8 h-auto w-[420px] shadow-sm relative z-10">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/verify" element={<VerifyEmailPage />} />
            <Route path="/logout" element={<LogoutPage />} />
          </Routes>
        </div>
        <div className="absolute bottom-1 left-0 right-0 text-center text-xs text-gray-600 dark:text-gray-400">
          © {new Date().getFullYear()} Teneo. All rights reserved.
        </div>
      </div>
    </div>
  );
}
