export const formatNumber = (num: number | string): string => {
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;
  return isNaN(parsedNum)
    ? num.toString()
    : parsedNum.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
};

export const prepareInviteLink = (code: string) => {
  return `Hi, i use the Teneo Community Node and earn rewards by contributing to a decentralized data network. Use my Referral Code: ${code} to get 1,000 Extra Teneo Points, when signing up. Join now and earn your share! bit.ly/teneo-community-node`;
};
export const formatTimestamp = (timestamp: string): string => {
  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      // Handle invalid date
      return "Invalid date";
    }
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
  } catch (error) {
    return "Invalid date";
  }
};
