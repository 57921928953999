import React from "react";
import { useTheme } from "../../app/context/ThemeContext";
import { TeneoIcon, TeneoIconLight } from "features/auth/assets/logos";

const LoadingSpinner: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  const theme = useTheme();
  return (
    <div className="flex flex-col gap-4 animate-fade-in  items-center justify-center w-full h-full ">
      <div className={`h-14 w-14  animate-spin-slow ${className}`}>
        {!theme.darkMode ? (
          <TeneoIcon className="h-full w-full dark:hidden" />
        ) : (
          <TeneoIconLight className="h-full w-full hidden dark:block" />
        )}
      </div>
    </div>
  );
};

export default LoadingSpinner;
