import React, { useState } from "react";
import { Icon } from "../../../../../components/icons/Icon";
import { Icons } from "../../../../../components/common/Icons";
import { useAppContext } from "../../../../../app/context/AppContext";
import { prepareInviteLink } from "../../../../../utils/helper";

const ReferralLink: React.FC = () => {
  const { user } = useAppContext();
  const referralMessage = prepareInviteLink(user.personal_code);
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(user.personal_code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const shareLinks = [
    {
      platform: "Reddit",
      icon: <Icons.Reddit size={20} />,
      url: `https://reddit.com/submit?url=${encodeURIComponent(
        referralMessage
      )}&title=Join%20Teneo`,
    },
    {
      platform: "X",
      icon: <Icons.X size={20} />,
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        referralMessage
      )}`,
    },
    {
      platform: "Discord",
      icon: <Icons.Discord size={20} />,
      url: `https://discord.com/channels/@me?content=${encodeURIComponent(
        referralMessage
      )}`,
    },
    {
      platform: "Telegram",
      icon: <Icons.Telegram size={20} />,
      url: `https://t.me/share/url?url=${encodeURIComponent(referralMessage)}`,
    },
  ];

  return (
    <div className="mb-8 bg-background-secondary-light dark:bg-background-secondary-dark p-6">
      <h2 className="text-xl font-medium mb-2 text-text-primary-light dark:text-text-primary-dark">
        Referral Link
      </h2>
      <p className="text-text-secondary-light dark:text-text-secondary-dark text-sm mb-4">
        Copy referral code to send to your friends or share to multiple
        platforms.
      </p>

      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={user.personal_code}
            readOnly
            className="flex-1 bg-white dark:bg-background-primary-dark border border-background-divide-light dark:border-background-divide-dark px-4 py-2 text-text-primary-light dark:text-text-primary-dark"
          />
          <button
            onClick={handleCopy}
            className="flex  w-44 space-x-2 bg-background-primary-dark text-white items-center justify-center py-2 hover:opacity-90 transition-opacity"
          >
            <span>{copied ? "Copied!" : "Copy Code"}</span>
            <Icon name={copied ? "check" : "copy"} className="w-5 h-5" />
          </button>
        </div>

        <div className="flex items-center">
          <div className="flex-1 border-t border-background-divide-light dark:border-background-divide-dark"></div>
          <span className="text-text-secondary-light dark:text-text-secondary-dark text-sm px-4">
            or
          </span>
          <div className="flex-1 border-t border-background-divide-light dark:border-background-divide-dark"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {shareLinks.map((link) => (
            <a
              key={link.platform}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center space-x-2 px-4 py-2 border border-background-divide-light dark:border-background-divide-dark bg-background-tertiary-light dark:bg-background-tertiary-dark  transition-colors"
            >
              {link.icon}
              <span className="text-text-primary-light dark:text-text-primary-dark">
                Share to {link.platform}
              </span>
              <Icon name="arrow" className="w-4 h-4" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferralLink;
