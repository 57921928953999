import React, { useState } from "react";
import { supabase } from "../../../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "../assets/logos";
import { checkIfUserExists } from "../../../app/data/api";

export default function SignupPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [inviteCodeError, setInviteCodeError] = useState("");
  const [error, setError] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSignUpWithInvite = async () => {
    setLoadingSignUp(true);
    setError("");
    if (!email) {
      setError("Email is required");
      setLoadingSignUp(false);
      return;
    }
    if (!password) {
      setError("Password is required");
      setLoadingSignUp(false);
      return;
    }
    if (!confirmPassword) {
      setError("Confirm password is required");
      setLoadingSignUp(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoadingSignUp(false);
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      setLoadingSignUp(false);
      return;
    }
    if (!/[A-Z]/.test(password)) {
      setError("Password must contain at least one uppercase letter");
      setLoadingSignUp(false);
      return;
    }
    if (!/[a-z]/.test(password)) {
      setError("Password must contain at least one lowercase letter");
      setLoadingSignUp(false);
      return;
    }
    if (!/\d/.test(password)) {
      setError("Password must contain at least one number");
      setLoadingSignUp(false);
      return;
    }
    if (inviteCode && inviteCode.length !== 5) {
      setInviteCodeError("Invite code must be 5 characters long");
      setLoadingSignUp(false);
      return;
    }

    const alreadyExists = await checkIfUserExists(email);
    if (alreadyExists) {
      setError("User already existis with this email");
      setLoadingSignUp(false);
      return;
    }
    const url = window.location.origin;
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${url}/auth/verify`,
        data: {
          invited_by: inviteCode,
        },
      },
    });

    if (error) {
      setError(error.message);
    } else if (data.user?.confirmation_sent_at) {
      console.log("Confirmation email sent");
    }

    setLoadingSignUp(false);
    setSignupSuccess(true);
  };

  return (
    <div className="flex  flex-col text-sm items-center justify-center">
      {signupSuccess ? (
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="text-2xl font-semibold mb-4">Check your email</h2>
          <p className="text-gray-600 mb-8">
            We've sent a confirmation link to your email address. Please click
            the link to verify your account.
          </p>
        </div>
      ) : (
        <>
          <h1 className="text-2xl text-[#1D1F23] font-normal text-center mb-2">
            Create an account
          </h1>
          <p className="text-center text-sm text-[#797E80] mb-6">
            to get started with Teneo.
          </p>

          <div onSubmit={handleSignUpWithInvite} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-[#3C3F40] mb-2">
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-[#E5E7E8]  focus:outline-none focus:ring-1 focus:ring-[#055E5E]"
                placeholder="Enter your email"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-[#3C3F40] mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  className={`w-full px-3 py-2 border ${"border-gray-300 focus:border-[#055E5E]"}`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                </button>
              </div>
              <div
                className={`transform transition-all duration-300 ease-in-out overflow-hidden ${
                  isPasswordFocused
                    ? "max-h-20 opacity-100 translate-y-0"
                    : "max-h-0 opacity-0 -translate-y-2"
                }`}
              >
                <p className={`mt-2 text-sm ${"text-[#797E80]"}`}>
                  Password needs at least 8 characters, 1 number and 1 uppercase
                  letter.
                </p>
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-[#3C3F40] mb-2">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`w-full px-3 py-2 border ${"border-gray-300 focus:border-[#055E5E]"}`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}
                </button>
              </div>
            </div>

            <div>
              <label htmlFor="referral" className="block text-[#3C3F40] mb-2">
                Referral code <span className="text-[#797E80]">(Optional)</span>
              </label>
              <input
                id="referral"
                type="text"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                className="w-full p-2 border border-[#E5E7E8]  focus:outline-none focus:ring-1 focus:ring-[#055E5E]"
                placeholder="Enter your referral code"
              />
            </div>
            {inviteCodeError && (
              <p className="text-red-500 text-sm">{inviteCodeError}</p>
            )}
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <div className="text-sm text-center text-[#797E80]">
              By creating an account, I acknowledge that I agree to Teneo's{" "}
              <a
                href="https://cdn.prod.website-files.com/665c71122bb2018f6ed3f9c9/676923cf32c9165e2a006b87_Privacy%20Policy%20Community%20Node%2012_24.pdf"
                className="text-[#055E5E] hover:underline"
              >
                Privacy Policy
              </a>
              .
            </div>

            <button
              onClick={handleSignUpWithInvite}
              disabled={
                loadingSignUp || !email || !password || !confirmPassword
              }
              className="w-full bg-[#0044FF] disabled:opacity-50 py-3 text-white  transition-colors"
            >
              {loadingSignUp ? "Loading..." : "Create an account"}
            </button>

            <div className="text-center text-sm text-[#797E80]">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/auth")}
                className="text-[#055E5E] cursor-pointer underline"
              >
                Login
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
