import { toast, ToastOptions } from "react-hot-toast";

interface ToastProps {
  message: string;
  type: "success" | "error";
}

const toastStyles: ToastOptions = {
  duration: 4000,
  position: "top-right",
  style: {
    maxWidth: "500px",
    padding: "16px",
    borderRadius: "4px",
  },
};

export const showToast = ({ message, type }: ToastProps) => {
  const baseClasses = "flex rounded-none items-center justify-between gap-4";

  if (type === "success") {
    toast.success(
      (t) => (
        <div className={`${baseClasses} text-white`}>
          <div className="flex items-center gap-3">
            <div className="bg-brand-teal-1/20"></div>
            <span>{message}</span>
          </div>
        </div>
      ),
      {
        ...toastStyles,
        className: "!bg-brand-teal-5 !text-white",
      }
    );
  } else {
    toast.error(
      (t) => (
        <div
          className={`${baseClasses} text-text-primary-light dark:text-primary-600`}
        >
          <div className="flex items-center gap-2">
            <span>{message}</span>
          </div>
          <button onClick={() => toast.dismiss(t.id)}>
            {/* <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg> */}
          </button>
        </div>
      ),
      {
        ...toastStyles,
        className:
          "!bg-background-primary-light !dark:bg-background-primary-dark !border !border-background-tertiary-light !dark:border-background-tertiary-dark",
      }
    );
  }
};
