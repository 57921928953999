import { useState, useEffect } from "react";
import { Icon } from "components/icons/Icon";
import { NavLink, useNavigate } from "react-router-dom";
import ReferralTicket from "./ReferralTicket";
import { useAppContext } from "app/context/AppContext";
import {
  TeneoIcon,
  TeneoIconLight,
  TeneoLogo,
  TeneoLogoLight,
} from "features/auth/assets/logos";
const NavItem = ({ to, icon, children, isCollapsed, disabled }: any) => {
  if (disabled) {
    return (
      <li className="mb-4 relative opacity-60 cursor-not-allowed">
        <div
          className={`
          flex items-center 
          ${!isCollapsed ? "pl-2" : "justify-center"}
          text-gray-400 dark:text-gray-500
          p-2 min-w-[20px] min-h-[20px]
        `}
        >
          <Icon
            name={icon}
            className="h-5 w-5 text-gray-700 dark:text-gray-300 flex-shrink-0"
          />
          {!isCollapsed && <span className="ml-3">{children}</span>}
        </div>
      </li>
    );
  }

  return (
    <li className="mb-4 relative">
      <NavLink
        to={to}
        className={({ isActive }) => `
          flex items-center 
          ${!isCollapsed ? "pl-2" : "justify-center"} 
          ${
            isActive
              ? "before:absolute text-black font-normal dark:text-white  before:left-[-20px] before:top-0 before:h-full before:w-1 before:bg-brand-blue-1"
              : "text-gray-400 dark:text-gray-500"
          }
          hover:text-gray-700 dark:hover:text-gray-300
          transition-colors duration-200 p-2 min-w-[20px] min-h-[20px]
        `}
      >
        <Icon
          name={icon}
          className="h-5 w-5 text-gray-700 dark:text-gray-300 flex-shrink-0"
        />
        {!isCollapsed && <span className="ml-3">{children}</span>}
      </NavLink>
    </li>
  );
};

const Sidebar = () => {
  const { userOnboarded } = useAppContext();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`relative h-screen bg-sidebar-light dark:bg-sidebar-dark ${
        isCollapsed ? "w-16" : "w-[230px]"
      } flex flex-col p-5 border-r border-gray-200 dark:border-gray-800  duration-300`}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="absolute -right-3 top-5 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-full p-1 z-50"
      >
        {isCollapsed ? (
          <Icon
            name="chevron-right"
            className="h-4 w-4 text-brand-blue-1 dark:text-brand-blue-1"
          />
        ) : (
          <Icon
            name="chevron-left"
            className="h-4 w-4 text-brand-blue-1 dark:text-brand-blue-1"
          />
        )}
      </button>

      <nav className="flex-1">
        <ul className="flex flex-col h-full">
          <li className="mb-8">
            <NavLink to="/dashboard" className="block">
              {!isCollapsed ? (
                <>
                  <TeneoLogo className="h-5 block dark:hidden" />
                  <TeneoLogoLight className="h-5 hidden dark:block" />
                </>
              ) : (
                <>
                  <TeneoIcon className="h-5 dark:hidden" />
                  <TeneoIconLight className="h-5 hidden dark:block" />
                </>
              )}
            </NavLink>
          </li>
          <NavItem
            to="/dashboard"
            icon="dashboard"
            isCollapsed={isCollapsed}
            disabled={!userOnboarded}
          >
            Dashboard
          </NavItem>
          <NavItem
            to="/rewards"
            icon="gift"
            isCollapsed={isCollapsed}
            disabled={!userOnboarded}
          >
            Rewards
          </NavItem>
          <NavItem
            to="/referrals"
            icon="star"
            isCollapsed={isCollapsed}
            disabled={!userOnboarded}
          >
            Referrals
          </NavItem>
          <li className="mb-4 relative opacity-60 cursor-not-allowed">
            <div
              className={`
              flex items-center 
              ${!isCollapsed ? "pl-2" : "justify-center"}
              text-gray-400 dark:text-gray-500
              p-2 min-w-[20px] min-h-[20px]
            `}
            >
              <Icon
                name="flame"
                className="h-5 w-5 text-gray-700 dark:text-gray-300 flex-shrink-0"
              />
              {!isCollapsed && (
                <span className="ml-3  flex items-center">Coming soon</span>
              )}
            </div>
          </li>
          <NavItem
            to="/profile"
            icon="profile"
            isCollapsed={isCollapsed}
            disabled={!userOnboarded}
          >
            Profile
          </NavItem>
          {!isCollapsed && (
            <li className="mt-auto pt-4  border-gray-200 dark:border-gray-800">
              <ReferralTicket />
            </li>
          )}
          <li
            className={`${
              isCollapsed
                ? "absolute bottom-[4.4rem] left-5"
                : "mt-4 pt-4 border-t border-gray-400 dark:border-gray-800"
            }`}
          >
            <div
              onClick={() => navigate("/faqs")}
              className={`flex cursor-pointer dark:text-gray-300 items-center ${
                !isCollapsed ? "space-x-2" : ""
              } `}
            >
              <Icon
                name="file-text"
                className="h-5 w-5 text-gray-700 dark:text-gray-300"
              />
              {!isCollapsed && <span>Help & FAQs</span>}
            </div>
          </li>
          <li
            className={`${
              isCollapsed
                ? "absolute bottom-4 left-5"
                : "mt-4 pt-4  dark:border-gray-800"
            }`}
          >
            <button
              onClick={() => navigate("/auth/logout")}
              className={`flex items-center ${
                !isCollapsed ? "space-x-2" : ""
              } text-gray-700 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 transition-colors duration-200`}
            >
              <Icon
                name="logout"
                className="h-5 w-5 text-gray-700 dark:text-gray-300"
              />
              {!isCollapsed && <span>Log out</span>}
            </button>
          </li>
        </ul>
      </nav>
      {!isCollapsed && (
        <div className="mt-8 left-0 right-0 text-center text-xs text-gray-600 dark:text-gray-400">
          © {new Date().getFullYear()} Teneo. All rights reserved.
        </div>
      )}
    </div>
  );
};

export default Sidebar;
