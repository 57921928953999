import React from "react";
import { Icon } from "../../../../components/icons/Icon";

interface FAQItem {
  question: string;
  answer: React.ReactNode;
  isOpen?: boolean;
}

// Helper component to render a table
const PointsTable: React.FC<{
  title: string;
  headers: string[];
  rows: string[][];
  footnote?: string;
}> = ({ title, headers, rows, footnote }) => (
  <div className="w-full">
    <h3 className="text-lg font-medium mb-2 text-text-primary-light dark:text-text-primary-dark">
      {title}
    </h3>
    <div className="border border-gray-200 dark:border-gray-700">
      <table className="w-full">
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th
                key={i}
                className="text-left p-4 bg-background-secondary-light dark:bg-background-secondary-dark border-b border-gray-200 dark:border-gray-700 text-text-primary-light dark:text-text-primary-dark"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.map((cell, j) => (
                <td
                  key={j}
                  className="p-4 border-t border-gray-200 dark:border-gray-700 text-text-primary-light dark:text-text-primary-dark"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {footnote && (
      <p className="mt-2 text-sm text-text-secondary-light dark:text-text-secondary-dark">
        {footnote}
      </p>
    )}
  </div>
);

// Helper component to render text with links
const TextWithLinks: React.FC<{ text: string }> = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return (
    <>
      {parts.map((part, i) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={i}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="text-brand-blue-1 hover:underline"
            >
              {part}
            </a>
          );
        }
        return <span key={i}>{part}</span>;
      })}
    </>
  );
};

const FaqPage = () => {
  const [faqs, setFaqs] = React.useState<FAQItem[]>([
    {
      question: "What is the Teneo Community Node?",
      answer: (
        <div className="space-y-4">
          <p>
            The Teneo Community Node allows you to unlock public social media
            data streams from platforms like X, Reddit, TikTok, and Facebook. By
            running a node, you contribute to a decentralized network that
            bypasses traditional data access barriers, ensuring that users – not
            corporations – benefit from the value of the data.
          </p>
          <p>
            When you run a Teneo Community Node, you help make public social
            media data accessible to a broader community, all while earning
            rewards.
          </p>
          <p>
            ℹ️ Please note that we are currently still in the BETA phase. Some
            functions (such as linking to social media) will be rolled out in
            the coming weeks.
          </p>
          <TextWithLinks text="Learn more here: https://teneo.pro/community-node/" />
        </div>
      ),
      isOpen: true,
    },
    {
      question: "What are Teneo Points?",
      answer: (
        <TextWithLinks text="As a node operator you will be rewarded with Teneo Points. After the Token Generation Event (TGE) the Teneo Points will convert into $TENEO Tokens." />
      ),
      isOpen: false,
    },
    {
      question: "How can I get Teneo Points?",
      answer: (
        <div className="space-y-4">
          <p>
            Reward distribution varies based on your contribution. You can
            collect Teneo Points through connecting your Node, inviting new
            people to our network, reaching referral levels, achieving
            connectivity streaks or connecting Teneo to your socials.
          </p>
          <p>Please note that the points model can change any time.</p>
        </div>
      ),
      isOpen: false,
    },
    {
      question: "How many Teneo Points can I get through Node Connectivity?",
      answer: (
        <div className="space-y-4">
          <p>
            Teneo Points are designed to reward users for keeping their Teneo
            Community Nodes online for extended periods. The main goal is to
            ensure that nodes remain connected as often and long as possible.
          </p>

          <div className="bg-background-secondary-light dark:bg-background-secondary-dark p-4 rounded">
            <h4 className="font-medium mb-2">Heartbeats and Points</h4>
            <p>
              Each node in the Teneo network sends regular "heartbeats" to
              signal that it is online and functioning. The longer your node
              stays online and maintains these heartbeats, the more Connectivity
              Points you earn.
            </p>
          </div>

          <PointsTable
            title="Node Connectivity"
            headers={["Points per Heartbeat*", "Max Points per Day"]}
            rows={[["25", "2,400"]]}
            footnote="*Every 15 minutes a heartbeat is sent. A day consists of 96 heartbeats in total that evaluate if your Node is connected and successfully running."
          />

          <div>
            <h4 className="font-medium mb-2">Connectivity Streaks</h4>
            <p className="mb-4">
              Since Version 2.0.0 you can collect Connectivity Streaks. If your
              node remains online for a certain number of heartbeats you are
              able to hit Streaks that allow you to earn additional Teneo
              Points.
            </p>

            <PointsTable
              title="Connectivity Streaks*"
              headers={[
                "Duration",
                "No. of Heartbeats (HB)",
                "Additional Teneo Points",
              ]}
              rows={[
                ["1 day", "96 HB", "2,500"],
                ["7 days", "672 HB", "10,000"],
                ["14 days", "1,344 HB", "25,000"],
                ["1 month", "2,880 HB", "50,000"],
              ]}
              footnote="*Applicable for heartbeats from Version 2.0.0."
            />
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      question: "How many Teneo Points can I get by inviting my friends?",
      answer: (
        <div className="space-y-4">
          <PointsTable
            title="Invite a Friend"
            headers={["Points per Referral*", "Points per Invitee"]}
            rows={[["2,000", "1,000"]]}
            footnote="*A referral counts as soon as the invitee has run 100 successful heartbeats."
          />

          <div>
            <h4 className="font-medium mb-2">Referral Levels</h4>
            <p className="mb-4">
              Since Version 2.0.0, you can earn additional rewards by achieving
              Referral Levels. A large ecosystem strengthens the decentralized
              infrastructure, making the node network more resilient and
              efficient. We want to reward your efforts.
            </p>

            <PointsTable
              title="Referral Levels**"
              headers={["Friends Referred", "Additional Teneo Points"]}
              rows={[
                ["5 friends", "5,000"],
                ["10 friends", "5,000"],
                ["20 friends", "10,000"],
                ["30 friends", "10,000"],
                ["50 friends", "25,000"],
              ]}
              footnote="**Applicable for invitations from Version 2.0.0."
            />
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      question: "Are there any further ways to boost my rewards?",
      answer: (
        <p>
          Connect your Teneo Community Node to Discord and follow us on X to
          earn even more Teneo Points.
        </p>
      ),
      isOpen: false,
    },
    {
      question: "How does the Points Breakdown on the Dashboard work?",
      answer: (
        <div className="space-y-4">
          <p>
            The Points Breakdown categorizes your earned points into four
            sections:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Connectivity:</strong> Points earned from your node's
              Heartbeats (HB), which track how long your node stays online and
              active.
            </li>
            <li>
              <strong>Referral:</strong> Points earned from successful
              invitations. Your invitee must sign up using your referral code
              and run their node for at least 100 Heartbeats to qualify.
            </li>
            <li>
              <strong>Rewards:</strong> Points from additional activities like
              verification steps, achieving referral levels, and maintaining
              connectivity streaks.
            </li>
            <li>
              <strong>Others:</strong> Any remaining points that don't fall
              directly into the above categories, such as giveaway prizes. This
              ensures your total points are always accurate.
            </li>
          </ul>
        </div>
      ),
      isOpen: false,
    },
    {
      question: "What do the Daily Points in the Dashboard include?",
      answer: (
        <p>
          The Daily Points shown on your dashboard reflect only the Connectivity
          Points you’ve earned from your node’s Heartbeats (HB) on that day.
          This feature helps you track how consistent your node has been online
          and contributing to the network over time. Points from other
          categories, like Referrals or Rewards, are not included in this
          calculation and are tracked separately.
        </p>
      ),
      isOpen: false,
    },
  ]);

  const toggleFAQ = (index: number) => {
    setFaqs(
      faqs.map((faq, i) => ({
        ...faq,
        isOpen: i === index ? !faq.isOpen : false,
      }))
    );
  };

  return (
    <div className="mx-auto px-7 py-8">
      <h2 className="text-2xl font-medium text-text-primary-light dark:text-text-primary-dark mb-8">
        Frequently Asked Questions
      </h2>

      <div className="divide-y divide-gray-200 dark:divide-gray-800">
        {faqs.map((faq, index) => (
          <div key={index}>
            <button
              className="w-full text-left py-6 flex justify-between items-center group"
              onClick={() => toggleFAQ(index)}
            >
              <span className="text-base text-text-primary-light dark:text-text-primary-dark">
                {faq.question}
              </span>
              <Icon
                name={faq.isOpen ? "minus" : "plus"}
                className={`h-5 w-5 text-text-primary-light dark:text-text-primary-dark flex-shrink-0 transition-transform duration-300 ${
                  faq.isOpen ? "rotate-180" : "rotate-0"
                }`}
              />
            </button>

            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden ${
                faq.isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
              }`}
            >
              <div className="pb-6">
                <div className="text-text-secondary-light dark:text-text-secondary-dark">
                  {faq.answer}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12 p-6 bg-background-secondary-light dark:bg-background-secondary-dark">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-xl font-medium text-text-primary-light dark:text-text-primary-dark">
              Need Support?
            </h2>
            <p className="text-text-secondary-light dark:text-text-secondary-dark mt-1">
              If you run into any issues while installing or running your Teneo
              Community Node, don't worry – we've got your back! For any
              questions or technical support, feel free to reach out to us in
              the Tech Feedback Channel in our Discord server:
            </p>
            <a
              href="https://discord.gg/teneoprotocol"
              target="_blank"
              rel="noopener noreferrer"
              className="text-brand-blue-1 hover:underline"
            >
              https://discord.gg/teneoprotocol
            </a>
          </div>
        </div>
      </div>
      <div className="  mt-4 -mb-2 text-center text-xs text-gray-600 dark:text-gray-400">
        © {new Date().getFullYear()} Teneo. All rights reserved.
      </div>
    </div>
  );
};

export default FaqPage;
