import { DiscordLogo, TeneoLogo, XLogo } from "../assets/logos";
import { Icon } from "components/icons";

export default function AuthHeader() {
  return (
    <header className="w-full border-b border-[#BBC4C7] h-16 px-6 flex justify-between items-center relative z-20">
      <a href="/" className="flex items-start  gap-1 text-lg">
        <TeneoLogo className="w-20" />
        {/* <span className="text-[8px]">BETA</span> */}
      </a>
      <div className="flex items-center justify-end ">
        <div className="flex items-center justify-center gap-3  w-[calc(100vw/8)]">
          <a
            href="https://x.com/teneo_protocol"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2"
          >
            <XLogo />
          </a>
          <a
            href="https://discord.gg/teneoprotocol"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2"
          >
            <DiscordLogo />
          </a>
        </div>
        <a
          href="https://teneo.pro"
          target="_blank"
          rel="noopener noreferrer"
          className="w-[calc(100vw/8)] flex gap-1 min-w-[160px] -mr-6 h-16 items-center justify-center px-4 flex bg-black text-white hover:bg-opacity-90 transition-colors"
        >
          Get started
          <Icon name="arrow" size={26} className="text-current" />
        </a>
      </div>
    </header>
  );
}
