import axios from "axios";

export const resourceApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
export const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
  },
});

export const login = async (email: string, password: string) => {
  try {
    const response = await authApi.post("/api/login", { email, password });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export const checkIfUserExists = async (email: string) => {
  try {
    const response = await authApi.post("/api/check-user-exists", { email });

    if (response.status === 200) {
      return response.data.exists;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const getUser = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await authApi.get("/api/user", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response;
};

export const changePassword = async (
  accessToken: string,
  newPassword: string
) => {
  try {
    const response = await authApi.post(
      "/api/update-password",
      {
        newPassword: newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export const changeEmail = async (accessToken: string, newEmail: string) => {
  try {
    const response = await authApi.post(
      "/api/change-email",
      {
        newEmail: newEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response;
    }
    throw error;
  }
};

export const checkIfUserOnboarded = async (accessToken: string) => {
  try {
    const response = resourceApi.get("/api/users/user-onboarded", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export const onboardUser = async (userId: string) => {
  try {
    const response = resourceApi.post("/api/users/onboard", {
      userId,
    });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export const getUserStats = async (accessToken: string) => {
  try {
    const response = resourceApi.get("/api/users/stats", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export const getUserReferrals = async (accessToken: string) => {
  try {
    const response = resourceApi.get("/api/users/referrals", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};
// todo: double check why there is 2 get referrals

export const getReferrals = async () => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await resourceApi.get("/api/users/referrals", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export const claimReferral = async (referralId: string) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await resourceApi.post("/api/users/referrals/claim", {
    referralId,
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response;
};

export const getPointsHistory = async () => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await resourceApi.get("/api/users/points-history", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

function errorHandler(error: any) {
  if (axios.isAxiosError(error) && error.response) {
    return error.response;
  }
  throw error;
}
