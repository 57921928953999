import React, { useEffect, useState } from "react";
import StatWidget from "../../components/StatWidget";
import TableWidget from "../../components/TableWidget";
import GaugeWidget from "./components/GaugeWidget";
import { DashboardGrid, DashboardSection } from "../../layouts/DashboardGrid";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";
import {
  checkIfUserOnboarded,
  // getPointsHistory,
  getUserReferrals,
  getUserStats,
  claimReferral,
} from "../../../../app/data/api";
import { useAppContext } from "../../../../app/context/AppContext";
import ComingSoonWidget from "./components/ComingSoonWidget";
import NodeUptime from "./components/NodeUptime";
import { useNavigate } from "react-router-dom";
import ChartWidget from "./components/ChartWidget";
import { showToast } from "../../../../components/common/Toast";

const DashboardPage: React.FC = () => {
  const { userStats, setUserStats, accessToken } = useAppContext();
  const [referrals, setReferrals] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [pointsHistory, setPointsHistory] = useState<any>(null);

  const { setUserOnboarded } = useAppContext();
  const navigate = useNavigate();
  async function loadData() {
    setIsLoading(true);
    if (!accessToken) {
      return;
    }

    const userOnboarded = await checkIfUserOnboarded(accessToken);
    if (!userOnboarded?.data.success) {
      navigate("/activate");
      setUserOnboarded(false);
      return;
    }

    setUserOnboarded(true);
    // const pointsHistoryResponse = await getPointsHistory();
    // console.log(pointsHistoryResponse?.data);
    // setPointsHistory(pointsHistoryResponse?.data);

    const userStatsResponse = await getUserStats(accessToken);
    const referralsResponse = await getUserReferrals(accessToken);
    const transformedReferrals = referralsResponse?.data.referrals
      .map((ref: any) => ({
        id: ref.id,
        user: ref.inviteeEmail || "Anonymous",
        heartbeat: ref.inviteeHeartbeats.toString(),
        status: ref.status as "Success" | "Pending",
        date: new Date(ref.invitedAt).toLocaleDateString(
          navigator.language || "en-US",
          {
            month: "short",
            day: "numeric",
            year: "numeric",
          }
        ),
      }))
      .sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );

    setUserStats(userStatsResponse?.data);
    setReferrals(transformedReferrals);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [accessToken]);

  const refreshReferralsOnly = async () => {
    if (!accessToken) return;
    
    const referralsResponse = await getUserReferrals(accessToken);
    const transformedReferrals = referralsResponse?.data.referrals
      .map((ref: any) => ({
        id: ref.id,
        user: ref.inviteeEmail || "Anonymous",
        heartbeat: ref.inviteeHeartbeats.toString(),
        status: ref.status as "Success" | "Pending" | "Claimable",
        date: new Date(ref.invitedAt).toLocaleDateString(),
      }))
      .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

    setReferrals(transformedReferrals);
  };

  const handleClaimReferral = async (referralId: string) => {
    try {
      const response = await claimReferral(referralId);
      
      if (response?.data?.success) {
        showToast({
          message: "Referral points claimed successfully!",
          type: "success",
        });
        // Refresh only referrals
        await refreshReferralsOnly();
      } else {
        showToast({
          message: "Failed to claim referral points",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error claiming referral:", error);
      showToast({
        message: "Failed to claim referral points",
        type: "error",
      });
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!userStats || !referrals) {
    return <LoadingSpinner />;
  }
  return (
    <DashboardGrid className="bg-background-primary-light dark:bg-background-primary-dark">
      <DashboardSection className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <StatWidget
          className="bg-background-secondary-light dark:bg-background-secondary-dark  p-6 shadow-sm"
          title="Daily Points"
          value={userStats?.points_today || 0}
          timeFrame="Today"
        />

        <StatWidget
          className="bg-background-secondary-light dark:bg-background-secondary-dark  p-6 shadow-sm"
          title="Total Points"
          value={userStats?.points_total || 0}
          timeFrame="All time"
        />
        <StatWidget
          className="bg-background-secondary-light dark:bg-background-secondary-dark  p-6 shadow-sm"
          title="Total Heartbeats"
          value={userStats?.heartbeats || 0}
          timeFrame="All time"
        />
      </DashboardSection>
      {/* <DashboardSection className="bg-background-secondary-light dark:bg-background-secondary-dark dak:text-white p-6">
        <ComingSoonWidget title="Coming soon" />
      </DashboardSection> */}
      <DashboardSection className="grid-cols-1 lg:grid-cols-2 gap-6">
        <TableWidget
          limit={5}
          className="bg-background-secondary-light dark:bg-background-secondary-dark  p-6 shadow-sm"
          data={referrals}
          viewMoreLink="/referrals"
          onClaimReferral={handleClaimReferral}
        />

        <GaugeWidget
          className="bg-background-secondary-light w-auto dark:bg-background-secondary-dark  p-6 shadow-sm"
          title="Points Breakdown"
          totalPoints={userStats?.points_total || 0}
          breakdown={userStats?.points_breakdown || []}
        />
      </DashboardSection>

      <DashboardSection className="grid-cols-2">
        <NodeUptime pointsToady={userStats?.points_today || 0} />
        <ComingSoonWidget title="Coming soon" />
      </DashboardSection>
      <div className=" relative mb-3 -mt-3 text-center text-xs text-gray-600 dark:text-gray-400">
        © {new Date().getFullYear()} Teneo. All rights reserved.
      </div>
    </DashboardGrid>
  );
};

export default DashboardPage;
